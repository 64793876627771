
import { db } from "@/main";
import mixin from "@/mixin";
import { doc } from "@firebase/firestore";
import { updateDoc } from "firebase/firestore";
import MouseFollower from "mouse-follower";
import * as THREE from "three";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class Project extends mixins(mixin) {
  @Prop() id;
  @Prop() name;
  @Prop() slug;
  @Prop() description;
  @Prop() services;
  @Prop() servicesItems;
  @Prop() image;
  @Prop() year;
  @Prop() allProjectInfo;
  @Prop() projectIndex;

  projectToEdit = {
    name: "",
    priority: 1,
    description: {
      es: "",
      us: "",
    },
    services: [],
    is_featured_project: false,
  };
  mounted() {
    this.projectToEdit = {
      name: this.name,
      priority: this.allProjectInfo.priority,
      description: {
        es: this.description,
        us: this.description,
      },
      services: this.servicesItems,
      is_featured_project: Object.prototype.hasOwnProperty.call(
        this.allProjectInfo,
        "is_featured_project"
      )
        ? this.allProjectInfo.is_featured_project
        : false,
    };
    //console.log(this.image);
    const target = document.querySelectorAll(".animation");
    const classProject = document.querySelector("." + this.slug);
    const assetProject = document.querySelector(".assetProject") as HTMLElement;

    assetProject!.addEventListener("mousemove", (e) => {
      console.log("mousemove");
      console.log(e.pageY);
      console.log(assetProject!.offsetTop);
      /*const x = e.pageX - assetProject!.offsetLeft;
      const y = e.pageY;*/
      const x = e.clientX;
      const y = e.clientY;
      console.log("x: " + x);
      console.log("y: " + y);

      //assetProject!.style.setProperty("--x", x + "px");
      //assetProject!.style.setProperty("--y", y + "px");
    });

    if (this.$store.state.cursor !== null) {
      classProject!.addEventListener("mouseenter", () => {
        this.$store.state.cursor.setText(
          this.$store.state.texts.others.see[this.$store.state.lang]
        );
      });

      classProject!.addEventListener("mouseleave", () => {
        this.$store.state.cursor.removeText();
      });

      classProject!.addEventListener("click", () => {
        this.$store.state.projectClicked = true;
        this.$store.state.cursor.removeText();
      });
    }
    for (let i = 0; i < target.length; i++) {
      if (target[i] !== undefined && target[i] !== null)
        this.$store.state.observer.observe(target[i]);
    }
  }

  onSelectedMediaChange(val) {
    console.log(val);
    this.$store.state.selectedMedia = val;
    const refToUpdate = doc(db, "website", "data", "projects", this.id);
    this.updateMedia(val, this.slug, refToUpdate, "main_image");
  }

  async updateInfo() {
    const refToUpdate = doc(db, "website", "data", "projects", this.id);
    await updateDoc(refToUpdate, {
      name: this.projectToEdit.name,
      description: this.projectToEdit.description,
      priority: this.projectToEdit.priority,
      services: this.projectToEdit.services,
      is_featured_project: this.projectToEdit.is_featured_project,
    })
      .then(() => {
        this.showNotification(
          "success",
          "Proyecto actualizado",
          "Se actualizó correctamente",
          2000
        );
      })
      .catch((error) => {
        console.log(error);
        this.showNotification(
          "fail",
          "Error",
          "Ocurrió un error al actualizar la imagen",
          2000
        );
      });
  }

  goToProjectDetail() {
    console.log(this.id);
    if (this.$store.state.cursor !== null) {
      this.$store.state.cursor.hide();
    }
    this.$store.state.sortedProjects = [...this.$store.state.allProjects];
    this.$store.state.sortedProjects.splice(this.projectIndex, 1);
    this.$store.state.sortedProjects.unshift(this.allProjectInfo);
    this.$store.state.isLoaded = false;
    this.$router.push({
      name: "project-details",
      params: {
        /*id: this.id,
        name: this.name,*/
        slug: this.slug,
      },
      //target: "_blank"
    });
    /*
    console.log(routeData)
    window.open(routeData.href, "_blank");*/
  }
}
