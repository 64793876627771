import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import "izitoast/dist/js/iziToast.min.js";
import "@/assets/css/style.css";
import "@/assets/css/index.scss";
import "@/assets/css/locomotive.css";
import "@/assets/css/style_cursor.css";
import firebase from "./firebase/init";
import mixin from "@/mixin";
import "animate.css";

Vue.config.productionTip = false;

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

export const db = getFirestore(firebase); //firebase.firestore();
export const auth = getAuth(firebase);
export const storage = getStorage(firebase); //firebase.storage();
export const storageRef = ref(storage);

new Vue({
  router,
  store,
  vuetify,
  async created() {
    await mixin.getServices();
    await mixin.getProjects();
    await mixin.getInfo();
  },
  render: h => h(App)
}).$mount("#app");
