
import { db, storage } from "@/main";
import mixin from "@/mixin";
import { addDoc, collection, doc, getDocs, setDoc } from "@firebase/firestore";
import { getDoc, limit, query, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Loader from "@/components/general/Loader.vue";
import TitleItem from "@/components/general/TitleItem.vue";
import SubTitleItem from "@/components/general/SubTitleItem.vue";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Component({
  components: {
    Loader,
    TitleItem,
    SubTitleItem
  }
})
export default class JobDetails extends mixins(mixin) {
  vacancyId = "";
  vacancy: any = null;
  applyToJob = false;
  loading = false;
  fields = {
    names: "",
    email: "",
    phone: "",
    cv: "",
    portfolio_url: "",
    additional_info: "",
    apply_date: new Date()
  };
  showMessage = false;
  success = false;
  successMessage: string = this.$store.state.texts.jobs.messages.success[
    this.$store.state.lang
  ];
  failMessage: string = this.$store.state.texts.jobs.messages.fail[
    this.$store.state.lang
  ];

  requiredRule: any = [];
  nameRules: any = [];
  emailRules: any = [];

  selectedCV: File = new File([""], "");

  async mounted() {
    this.requiredRule = [
      v =>
        v != undefined
          ? !!v ||
            this.$store.state.texts.rules.required[this.$store.state.lang]
          : ""
    ];
    this.nameRules = [
      v =>
        v != undefined
          ? !!v || this.$store.state.texts.rules.names[this.$store.state.lang]
          : ""
    ];
    this.emailRules = [
      v =>
        !!v ||
        this.$store.state.texts.rules.email.required[this.$store.state.lang],
      v =>
        /.+@.+\..+/.test(v) ||
        this.$store.state.texts.rules.email.format[this.$store.state.lang]
    ];

    this.$store.state.isLoaded = false;
    this.vacancyId = this.$route.params.id;

    const docRef = doc(db, "website", "requests", "vacancies", this.vacancyId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.vacancy = docSnap.data();
      this.vacancy["id"] = docSnap.id;
    }
    console.log(this.vacancy);
    this.$store.state.isLoaded = true;
  }

  onSelectedCVChange(val) {
    console.log(val);
    this.selectedCV = val;
  }

  showForm() {
    this.applyToJob = true;
    this.$store.state.scrollbar!.scrollTop = 0;
  }

  async sendRequest() {
    if ((this.$refs.formContact as any).validate()) {
      if (this.selectedCV != null && this.selectedCV !== undefined) {
        console.log("Correct");
        this.loading = true;
        const storageRef = ref(
          storage,
          "/jobs/" + this.vacancy.job + "/" + this.selectedCV.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedCV
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.fields.cv = downloadURL;
        const subColReff = collection(
          db,
          "website",
          "requests",
          "vacancies",
          this.vacancyId,
          "requests"
        );
        await addDoc(subColReff, this.fields)
          .then(() => {
            this.success = true;
            this.loading = false;
            this.showMessage = true;
          })
          .catch(error => {
            this.success = false;
            this.loading = false;
            this.showMessage = true;
            console.log("Error job details: " + error);
          });
      }
    } else {
      this.loading = false;
      this.showMessage = false;
      console.log("incorrect");
      this.showNotification(
        "info",
        "Falta info",
        "Verifica los campos e intenta nuevamente",
        2000
      );
    }
  }
}
