var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"ma-0 pa-0"},[_c('section',{class:{
      'section section-project-no-border section-80 pa-0':
        _vm.$vuetify.breakpoint.mdAndUp,
      'section section-project-no-border section-70 pa-0':
        _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-col',{staticClass:"section main-page-padding",attrs:{"md":"12","cols":"12"}},[_c('h1',{staticClass:"animation first-text"},[_vm._v(" "+_vm._s(_vm.$store.state.texts.services.main_title[_vm.$store.state.lang])+" ")]),_c('h1',{staticClass:"animation main-title-section second-text"},[_vm._v(" "+_vm._s(_vm.$store.state.texts.services.description[_vm.$store.state.lang])+" ")])])],1),_c('section',{class:{
      'section section-100 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
      'section section-70 pa-0': _vm.$vuetify.breakpoint.smAndDown
    },style:({ 'background-color': '#141D51' })},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('MainVideoComponent',{attrs:{"video":require('@/assets/videos/apps-webs-digitalexp.mp4')}})],1)],1),_c('section',{staticClass:"section-20"}),_c('v-col',{staticClass:"main-page-padding"},[_c('v-col',{staticClass:"mx-0 px-0"},_vm._l((_vm.$store.state.services),function(value,key,index){return _c('v-col',{key:index,staticClass:"my-16 mx-0 px-0"},[_c('ServiceComponent',{attrs:{"service":value,"serviceKey":key}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }