
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import mixin from "./mixin";
import { version } from "../package.json";
import { Watch } from "vue-property-decorator";
import anime from "animejs";
import MouseFollower from "mouse-follower";
import gsap from "gsap";
import Alert from "@/components/general/Alert.vue";
import LocomotiveScroll from "locomotive-scroll";
MouseFollower.registerGSAP(gsap);

@Component({
  components: {
    Header,
    Footer,
    Alert,
    LocomotiveScroll,
  },
})
export default class AppVue extends mixins(mixin) {
  snackbarVersion = true;
  lastScrollPosition = 0;
  overlap = false;
  loader = null as any;
  projectContent: any = undefined;
  customSelect: any = undefined;
  subjects: any;
  rectProjectDetails: any;
  style = { background: this.$vuetify.theme.themes.dark.background };
  interval;
  @Watch("$store.state.isLoaded")
  onIsLoadedChange(val) {
    //console.log("onIsLoadedChange: " + val);
    if (val) {
      //console.log("Is loaded: " + val);
    }
  }
  @Watch("$vuetify.theme.dark")
  onVuetifyThemeChange(val) {
    console.log("onVuetifyThemeChange");
    console.log(val);
    //(document.querySelector(':root')! as HTMLElement).style.setProperty('--menu-color', val ?  "#D8DA00": "#1A1A1A");
    //console.log((document.querySelector(':root')! as HTMLElement))
    document.documentElement.style.setProperty(
      "--menu-color",
      val ? "#D8DA00" : "#1A1A1A"
    );
  }

  @Watch("$store.state.menuDark")
  onMenuDarkChange(val) {
    //console.log("onIsLoadedChange: " + val);
    if (this.customSelect) {
      if (val) {
        //console.log("Is loaded: " + val);
        this.customSelect!.style.setProperty("--menu-color", "#fff");
      } else {
        this.customSelect!.style.setProperty("--menu-color", "#D8DA00");
      }
    }
  }

  async mounted() {
    //document.documentElement.style.setProperty('--menu-color', "#D8DA00");
    navigator.language == 'en' ? this.$store.state.lang = 'us' : 'es'
    this.$store.state.showFooter = true;
    document.documentElement.style.setProperty("--menu-color", "#ECF2F2");
    this.$store.state.cursor = this.$vuetify.breakpoint.mdAndUp
      ? new MouseFollower({
          container: document.body,
          speed: 0.55,
        })
      : null;

    const box = document.querySelector(".fixed-box");
    const el = document.querySelector(".fixed-element");

    if (this.$store.state.cursor !== null) {
      box!.addEventListener("mouseenter", () => {
        this.$store.state.cursor.setStick(el);
      });

      box!.addEventListener("mouseleave", () => {
        this.$store.state.cursor.removeStick();
      });
    }
    this.$store.state.scrollbar = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      multiplier: 0.3,
      smartphone: {
        breakpoint: 0,
        smooth: false,
      },
      tablet: {
        breakpoint: 0,
        smooth: true,
      },
    });
    console.log("Mounted scrollbar");
    console.log(this.$store.state.scrollbar);
    this.startListenHover();

    /*this.$store.state.scrollbar = Scrollbar.init(
      (document as any).querySelector("#main-page"),
      this.$vuetify.breakpoint.mdAndUp
        ? {
            damping: 0.05,
            thumbMinSize: 20,
            renderByPixels: true,
            continuousScrolling: true,
            plugins: {
              mobile: {
                // this is optional
                speed: 0.1,
              },
            },
          }
        : {
            plugins: {
              mobile: {
                // this is optional
                speed: 0.9,
              },
            },
          }
    );
    this.startListenHover();*/

    setTimeout(() => {
      if (this.$route.name !== "contact-code" && this.$store.state.codes.scs) {
        this.$store.state.showSCSDialog = true;
      } else {
        this.$store.state.showSCSDialog = false;
      }
    }, 3000);

    /*const animsRightLeft = gsap.utils.toArray<HTMLElement>(".anim-rightleft");
      console.log("animsRightLeft");
      console.log(animsRightLeft);

      animsRightLeft.forEach((anim) => {
        console.log(anim);
        gsap.to(anim, {
          x: 600,
          scrollTrigger: {
            trigger: anim,
            scrub: true,
          },
        });
      });*/
    //this.goToSection(id[1])

    /*
    USE OF SPLINE.DESIGN

    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      5,
      100000
    );
    this.camera.position.set(1000, 0, 1000);
    this.camera.quaternion.setFromEuler(new THREE.Euler(0, 0, 0));
    // scene
    this.scene = new THREE.Scene();
    // spline scene
    this.loader = new SplineLoader();
    console.log(this.loader)
    this.loader.load(
      "https://prod.spline.design/zcIubLpSx2VeOJHV/scene.splinecode",
      (splineScene) => {
        this.scene.add(splineScene);
      }
    );

    // renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setAnimationLoop(this.animate);
    document.body.appendChild(this.renderer.domElement);

    // scene settings
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;

    this.scene.background = new THREE.Color("#121316");
    this.renderer.setClearAlpha(0);

    // orbit controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.125;

    window.addEventListener("resize", this.onWindowResize);*/
  }

  /*onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  animate(time) {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }*/

  reload() {
    console.log("Reload");
    location.reload();
  }

  startListenHover() {
    console.log("startListenHover");
    this.$store.state.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.className.includes("footer")) {
          if (entry.intersectionRatio > 0) {
            this.$store.state.contactBtnColor = "#fff";
            this.$store.state.contactBtnIconColor = "#6F78B8";
          } else {
            this.$store.state.contactBtnColor = "#6F78B8";
            this.$store.state.contactBtnIconColor = "#fff";
          }
        }
        // If the element is visible
        /*if (entry.isIntersecting) {
          // Add the animation class
          if (entry.target.className.includes("animation")) {
            //console.log("Detected class: animation");
            //console.log(entry.target);
            anime({
              targets: entry.target,
              translateX: [50, 0],
              opacity: [0, 1],
              duration: 1200,
              easing: "easeInOutSine",
            });
          }

          if (entry.target.className.includes("animation-fadeInDown")) {
            //console.log("Detected class: animation");
            //console.log(entry.target);
            anime({
              targets: entry.target,
              translateX: [0, 0],
              translateY: [-50, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeInOutSine",
            });
          }
          if (entry.target.className.includes("anim-art")) {
            anime({
              targets: entry.target,
              translateY: [50, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeInOutSine",
            });
          }
        }*/
      });
    });

    /*const target = document.querySelectorAll(".animation-fadeInDown");
    for (let i = 0; i < target.length; i++) {
      if (target[i] !== undefined)
        this.$store.state.observer.observe(target[i]);
    }*/

    const header = document.querySelector(".header") as HTMLElement;
    const footer = document.querySelector(".footer");
    console.log(header);
    console.log(footer);
    console.log("-------");
    const darkSections = document.getElementsByClassName("dark-section");
    const intersectionObject = document.querySelector(".intersection-object");
    const intersectionBottomObject = document.querySelector(
      ".intersection-object-bottom"
    );
    this.customSelect = document.querySelector(
      ".v-select__selection"
    ) as HTMLElement;
    /*console.log("Listener scroll");
      if (scroll.offset.y > this.lastScrollPosition) {
        //Moving down
        this.$store.state.scrollValue+= 5
        console.log("Moving down");
      } else {
        //Moving up
        this.$store.state.scrollValue-= 5;
        console.log("Moving up");
      }
      this.lastScrollPosition = scroll.offset.y;

      console.log(this.$store.state.scrollValue);*/
    //console.log(this.$route.name);
    const mainDiv = document.querySelector(".v-application");
    let isDarkVisible = false;
    this.$store.state.scrollbar.on("scroll", (value) => {
      const rectHeader = header!.getBoundingClientRect();
      //const rectFooter = footer!.getBoundingClientRect();

      if (this.$route.path === "/design-service") {
        const rectIntersectionObject =
          intersectionObject!.getBoundingClientRect();

        const rectIntersectionObject2 = header!.getBoundingClientRect();

        const section1 = document.getElementById("section-1-hero");
        const section1Intersect = section1!.getBoundingClientRect();
        const isOverlapSection1 = this.getOverlap(
          rectIntersectionObject2,
          section1Intersect
        );

        const section2 = document.getElementById("section-2-design");
        const section2Intersect = section2!.getBoundingClientRect();
        const isOverlapSection2 = this.getOverlap(
          rectIntersectionObject2,
          section2Intersect
        );

        const section3 = document.getElementById("section-3-steps");
        const section3Intersect = section3!.getBoundingClientRect();
        const isOverlapSection3 = this.getOverlap(
          rectIntersectionObject2,
          section3Intersect
        );

        const section4 = document.getElementById("section-4-benefits");
        const section4Intersect = section4!.getBoundingClientRect();
        const isOverlapSection4 = this.getOverlap(
          rectIntersectionObject2,
          section4Intersect
        );

        const section5 = document.getElementById("section-5-for");
        const section5Intersect = section5!.getBoundingClientRect();
        const isOverlapSection5 = this.getOverlap(
          rectIntersectionObject2,
          section5Intersect
        );

        const section6 = document.getElementById("section-6-items");
        const section6Intersect = section6!.getBoundingClientRect();
        const isOverlapSection6 = this.getOverlap(
          rectIntersectionObject2,
          section6Intersect
        );

        const section7 = document.getElementById("section-7-projects");
        const section7Intersect = section7!.getBoundingClientRect();
        const isOverlapSection7 = this.getOverlap(
          rectIntersectionObject2,
          section7Intersect
        );

        const section8 = document.getElementById("section-8-areas");
        const section8Intersect = section8!.getBoundingClientRect();
        const isOverlapSection8 = this.getOverlap(
          rectIntersectionObject2,
          section8Intersect
        );

        const section9 = document.getElementById("section-9-plans");
        const section9Intersect = section9!.getBoundingClientRect();
        const isOverlapSection9 = this.getOverlap(
          rectIntersectionObject2,
          section9Intersect
        );

        const section10 = document.getElementById("section-10-faqs");
        const section10Intersect = section10!.getBoundingClientRect();
        const isOverlapSection10 = this.getOverlap(
          rectIntersectionObject2,
          section10Intersect
        );

        const section11 = document.getElementById("section-11-end");
        const section11Intersect = section11!.getBoundingClientRect();
        const isOverlapSection11 = this.getOverlap(
          rectIntersectionObject2,
          section11Intersect
        );

        const smileHero = document.getElementById(
          "smile-yellow"
        ) as HTMLElement;
        const purpleBorderSmileHero = document.getElementById(
          "purple-border-smile"
        ) as HTMLElement;
        const squareHero = document.getElementById(
          "purple-square"
        ) as HTMLElement;

        //Third
        const smilePurpleSteps = document.getElementById(
          "smile-purple-steps"
        ) as HTMLElement;
        const smileYellowBorderSteps = document.getElementById(
          "smile-yellow-border-steps"
        ) as HTMLElement;
        const purpleBorderSmileSteps = document.getElementById(
          "purple-border-smile-steps"
        ) as HTMLElement;

        //Benefits
        const pinkBorderSmileBenefits = document.getElementById(
          "pink-border-smile-benefits"
        ) as HTMLElement;
        const pinkSquareBenefits = document.getElementById(
          "pink-square-benefits"
        ) as HTMLElement;

        //For
        const smileYellowBorderFor = document.getElementById(
          "smile-yellow-border-for"
        ) as HTMLElement;
        const purpleBorderSmileFor = document.getElementById(
          "purple-border-smile-for"
        ) as HTMLElement;

        //Areas
        const squareYellowAreas = document.getElementById(
          "square-yellow-areas"
        ) as HTMLElement;

        //Projects
        const smileSquarePurpleBorderProjects = document.getElementById(
          "smile-square-purple-border-projects"
        ) as HTMLElement;
        const smileYellowBorderProjects = document.getElementById(
          "smile-yellow-border-projects"
        ) as HTMLElement;
        const purpleBorderSmileProjects = document.getElementById(
          "purple-border-smile-projects"
        ) as HTMLElement;

        //Faqs
        const squareYellowFaqs = document.getElementById(
          "square-yellow-faqs"
        ) as HTMLElement;

        //Faqs
        const smileYellowEnd = document.getElementById(
          "smile-yellow-end"
        ) as HTMLElement;

        const purpleSquareEnd = document.getElementById(
          "purple-square-end"
        ) as HTMLElement;

        if (smileHero && purpleBorderSmileHero && squareHero) {
          const rotate = value.scroll.y / 2;
          const rotate2 = value.scroll.y / 3;

          if (isOverlapSection1) {
            smileHero.style.transform = "rotate(" + rotate + "deg)";
            purpleBorderSmileHero.style.transform = "rotate(" + rotate + "deg)";
            squareHero.style.transform = "rotate(" + rotate + "deg)";
          }

          if (isOverlapSection3) {
            smilePurpleSteps.style.transform = "rotate(" + rotate2 + "deg)";
            smileYellowBorderSteps.style.transform =
              "rotate(" + rotate2 + "deg)";
            purpleBorderSmileSteps.style.transform =
              "rotate(" + rotate2 + "deg)";
          }

          if (isOverlapSection4) {
            pinkBorderSmileBenefits.style.transform =
              "rotate(" + rotate2 + "deg)";
            pinkSquareBenefits.style.transform = "rotate(" + rotate2 + "deg)";
          }

          if (isOverlapSection5) {
            console.log("isOverlapSection5");
            smileYellowBorderFor.style.transform = "rotate(" + rotate + "deg)";
            purpleBorderSmileFor.style.transform = "rotate(" + rotate + "deg)";
          }

          if (isOverlapSection7) {
            smileSquarePurpleBorderProjects.style.transform =
              "rotate(" + rotate + "deg)";
            smileYellowBorderProjects.style.transform =
              "rotate(" + rotate + "deg)";
            purpleBorderSmileProjects.style.transform =
              "rotate(" + rotate + "deg)";
          }

          if (isOverlapSection8) {
            console.log("isOverlapSection8");
            if (this.interval === undefined) this.randomChipColor();
            squareYellowAreas.style.transform = "rotate(" + rotate + "deg)";
          } else {
            if (this.interval != undefined) {
              clearInterval(this.interval);
              this.interval = undefined;
            }
            console.log("no isOverlapSection8");
          }

          if (isOverlapSection10) {
            squareYellowFaqs.style.transform = "rotate(" + rotate + "deg)";
          }

          if (isOverlapSection11) {
            smileYellowEnd.style.transform = "rotate(" + rotate + "deg)";
            purpleSquareEnd.style.transform = "rotate(" + rotate + "deg)";
          }

          /*        smilePurpleSteps.style.transform = "rotate(" + rotate + "deg)";
          smileYellowBorderSteps.style.transform = "rotate(" + rotate + "deg)";
          purpleBorderSmileSteps.style.transform = "rotate(" + rotate + "deg)";
          
          pinkBorderSmileBenefits.style.transform = "rotate(" + rotate + "deg)";
          pinkSquareBenefits.style.transform = "rotate(" + rotate + "deg)";
*/
        }
        for (let index = 0; index < darkSections.length; index++) {
          const item = darkSections[index];
          //console.log("item in darkSections");
          const rectDarkSection = item!.getBoundingClientRect();
          const isOverlapDarkSection = this.getOverlap(
            rectIntersectionObject,
            rectDarkSection
          );

          if (isOverlapDarkSection && !isDarkVisible) {
            console.log("Put dark");
            this.$vuetify.theme.dark = true;
            mainDiv?.classList.remove("theme--light");
            mainDiv?.classList.add("theme--dark");
            isDarkVisible = true;
            if (this.$store.state.menuDark) {
              this.$store.state.menuDark = false;
            }
            break;
          } else if (isOverlapDarkSection) {
            break;
          } else {
            if (isDarkVisible && index == darkSections.length - 1)
              isDarkVisible = false;
          }
        }
        if (isDarkVisible) return;

        if (this.$vuetify.theme.dark && !isDarkVisible) {
          console.log("Put light");
          this.$vuetify.theme.dark = false;
          mainDiv?.classList.remove("theme--dark");
          mainDiv?.classList.add("theme--light");
          if (!this.$store.state.menuDark) {
            this.$store.state.menuDark = true;
          }
        }
      }

      if (this.$route.path.includes("project")) {
        if (!this.$store.state.isOverlap) {
          if (this.$store.state.menuDark && this.$vuetify.breakpoint.mdAndUp) {
            this.$store.state.menuDark = false;
          }
          //console.log(this.$store.state.menuDark);
          //menu!.style.setProperty("--menu-color", "#161C42");
        } else {
          if (!this.$store.state.menuDark && this.$vuetify.breakpoint.mdAndUp) {
            //this.$store.state.menuDark = true;
            this.$store.state.menuDark = false;
          }
          //console.log(this.$store.state.menuDark);
          //menu!.style.setProperty("--menu-color", "#fff");
        }
        if (this.$route.name != "project-details") {
          if (
            this.projectContent !== undefined ||
            this.projectContent === null
          ) {
            this.projectContent = undefined;
          }
        }

        if (this.$route.name === "project-details") {
          if (
            this.projectContent === undefined ||
            this.projectContent === null
          ) {
            this.projectContent = document.querySelector(
              ".project-content"
            ) as HTMLElement;
          }
          if (this.projectContent != null && this.projectContent != undefined) {
            this.rectProjectDetails =
              this.projectContent.getBoundingClientRect();
            if (this.rectProjectDetails != undefined) {
              this.$store.state.isOverlap = !(
                rectHeader.right < this.rectProjectDetails.left ||
                rectHeader.left > this.rectProjectDetails.right ||
                rectHeader.bottom < this.rectProjectDetails.top ||
                rectHeader.top > this.rectProjectDetails.bottom
              );

              if (!this.$store.state.isOverlap) {
                if (
                  !this.$store.state.menuDark &&
                  this.$vuetify.breakpoint.mdAndUp
                ) {
                  this.$store.state.menuDark = true;
                }
                //menu!.style.setProperty("--menu-color", "#161C42");
              } else {
                if (
                  this.$store.state.menuDark &&
                  this.$vuetify.breakpoint.mdAndUp
                ) {
                  this.$store.state.menuDark = false;
                }
              }
            }
          }
        }
      }
    });

    const target = document.querySelectorAll(".animation .anim-art, .footer");
    for (let i = 0; i < target.length; i++) {
      if (target[i] !== undefined)
        this.$store.state.observer.observe(target[i]);
    }
    this.$store.state.isLoaded = true;
  }

  goToContactCode() {
    this.$store.state.showSCSDialog = false;
    this.$router.push({
      name: "contact-code",

      //target: "_blank"
    });
  }

  getOverlap(intersectObj1, intersectObj2) {
    return !(
      intersectObj1.right < intersectObj2.left ||
      intersectObj1.left > intersectObj2.right ||
      intersectObj1.bottom < intersectObj2.top ||
      intersectObj1.top > intersectObj2.bottom
    );
  }

  randomChipColor() {
    this.interval = setInterval(() => {
      const max =
        this.$store.state.texts.landings.design.sections[8].areas.length;
      const index = Math.floor(Math.random() * (max - 0 + 1) + 0);
      const element = document.getElementsByClassName(
        "chip-areas-change-color"
      );
      const chip = document.getElementById("chip_" + index);
      if (element.length > 0) {
        element[0].classList.remove("chip-areas-change-color");
      }
      setTimeout(() => {
        if (chip != null) {
          chip.classList.add("chip-areas-change-color");
        }
      }, 400);
    }, 1200);
  }
}
