var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.asset !== undefined)?_c('v-col',{staticClass:"ml-0 pl-0 anim-art",attrs:{"cols":"12","md":"12"}},[(!_vm.asset.includes('.mp4'))?_c('v-col',{class:{
      'ma-0 pl-0 pr-7 py-0': _vm.$vuetify.breakpoint.mdAndUp,
      'ma-0 pl-0 py-0': _vm.$vuetify.breakpoint.smAndDown
    },style:({
      'background-image': 'url(' + _vm.asset + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
      'background-origin': 'content-box',
      height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
      width: '100%'
    }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",style:({
      'background-image': 'url(' + _vm.asset + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
      height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px'
    }),attrs:{"cols":"12","md":"12"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }