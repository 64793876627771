import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Services from "../views/Services.vue";
import ProjectDetails from "../views/ProjectDetails.vue";
import ServiceDetails from "../views/ServiceDetails.vue";
import Contact from "../views/Contact.vue";
import ContactCode from "../views/ContactCode.vue";
import Jobs from "../views/Jobs.vue";
import JobDetails from "../views/JobDetails.vue";
import BriefWeb from "../views/BriefWeb.vue";
import BriefBrand from "../views/BriefBrand.vue";
import Page404 from "../views/Page404.vue";
import LandingDesign from "../views/LandingDesign.vue";
import ThankYou from "../views/ThankYou.vue";
import store from "../store";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import mixin from "@/mixin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Digital Exp",
      requiresAuth: false,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    props: true,
    meta: {
      title: "Proyectos",
      requiresAuth: false,
    },
  },
  {
    path: "/projects/:slug",
    name: "project-details",
    component: ProjectDetails,
    props: true,
    meta: {
      title: "Detalle Proyectos",
      requiresAuth: false,
    },
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    props: true,
    meta: {
      title: "Servicios",
      requiresAuth: false,
    },
  },
  {
    path: "/services/:slug",
    name: "service-details",
    component: ServiceDetails,
    props: true,
    meta: {
      title: "Detalle Servicio",
      requiresAuth: false,
    },
  },

  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "Digital Exp - Contact",
      requiresAuth: false,
    },
  },
  {
    path: "/design-service",
    name: "design-service",
    component: LandingDesign,
    meta: {
      title: "Digital Exp - Design Service",
      requiresAuth: false,
    },
  },
  
  {
    path: "/contact-code",
    name: "contact-code",
    component: ContactCode,
    meta: {
      title: "Digital Exp",
      requiresAuth: false,
    },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    meta: {
      title: "Digital Exp",
      requiresAuth: false,
    },
  },
  {
    path: "/jobs/:id",
    name: "job-details",
    component: JobDetails,
    props: true,
    meta: {
      title: "Detalle Trabajo",
      requiresAuth: false,
    },
  },

  {
    path: "/brief-web",
    name: "BriefWeb",
    component: BriefWeb,
    meta: {
      title: "Brief Web",
      requiresAuth: false,
    },
  },

  {
    path: "/brief-brand",
    name: "BriefBrand",
    component: BriefBrand,
    meta: {
      title: "Brief Marca",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    component: Page404,
    meta: {
      title: "Page not found",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    store.state.menuDark = false;
    /*if (savedPosition) {
      return savedPosition
    } else */ if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          //store.state.scrollbar!.scrollTop = 0;
          const element = document.getElementById("main-page") as HTMLElement;
          element.scrollTop = 0;
        }, 500);
      });
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          //store.state.scrollbar!.scrollTop = 0;
          const element = document.getElementById("main-page") as HTMLElement;
          element.scrollTop = 0;
        }, 100);
      });
    }
    /*if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }*/
    //return { x: 0, y: 0 };
  },
  routes,
});

router.afterEach((to) => {
  console.log(to);
  if (to !== undefined) {
    if (to.meta!.title) {
      if (to.name !== "home") {
        if (to.name === "project-details" || to.name === "service-details") {
          document.title =
            "Digital Exp - " + mixin.capitalizeName(to.params.slug);
        } else {
          document.title = "Digital Exp - " + to.meta!.title;
        }
      } else {
        document.title = to.meta!.title;
      }
    }
  }
});

export default router;
