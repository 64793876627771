import Vue from "vue";
import Component from "vue-class-component";
import iziToast from "izitoast";
import { db, auth, storage } from "./main";
import {
  doc,
  addDoc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  onSnapshot
} from "firebase/firestore";
import store from "./store";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

@Component
export default class Mixin extends Vue {
  logOut() {
    auth
      .signOut()
      .then(() => {
        this.$router.push("/login");
      })
      .catch(error => {
        console.log(error);
      });
  }

  static capitalizeName(name) {
    const finalName = name.toLowerCase();
    return finalName.charAt(0).toUpperCase() + finalName.slice(1);
  }

  getDayOfWeek(day: any) {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];

    return days[day];
  }

  getStatusName(value: number) {
    if (value == 1) {
      return "Creada";
    } else if (value == 2) {
      return "Asignada";
    } else if (value == 3) {
      return "En proceso";
    } else if (value == 4) {
      return "Reprogramada";
    } else if (value == 5) {
      return "Cancelada";
    } else if (value == 6) {
      return "Finalizada por trabajador";
    } else {
      return "Finalizada por administrador";
    }
  }

  formatDateWithHour(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return (
      day +
      "/" +
      (monthIndex + 1) +
      "/" +
      year +
      " - " +
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
    );
  }

  formatDateToName(date, hour) {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    if (hour) {
      return (
        day +
        " de " +
        monthNames[monthIndex] +
        " " +
        year +
        " - Hora: " +
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
      );
    } else {
      return day + " de " + monthNames[monthIndex] + " " + year;
    }
  }

  getMonthAndYear(date: any) {
    const monthNames = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ];
    const monthIndex = date.getUTCMonth();
    const year = date.getFullYear();

    return monthNames[monthIndex] + " " + year;
  }

  getMonthName(date: any) {
    const monthNames = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ];
    const monthIndex = date.getUTCMonth();

    return monthNames[monthIndex];
  }

  getDayAndMonthName(date: any) {
    const monthNames = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ];
    const day = date.getDate();
    const monthIndex = date.getUTCMonth();

    return day + " de " + monthNames[monthIndex];
  }

  formatDateToNameWithoutYear(date) {
    const monthNames = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();

    return (
      day +
      " " +
      monthNames[monthIndex] +
      " - " +
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
    );
  }

  formatNameForSearch(name) {
    const values: any[] = [];
    const words: any[] = name
      .toLowerCase()
      .trim()
      .split(" ");

    for (let i = 0; i < words.length; i++) {
      const splitText = words[i]
        .toLowerCase()
        .trim()
        .split("");
      let lastChar = "";
      for (let index = 0; index < splitText.length; index++) {
        if (index == 0) {
          values.unshift(splitText[index]);
        } else {
          let word = "";
          word = lastChar == " " ? "" : word;
          for (let subIndex = 0; subIndex <= index; subIndex++) {
            word = word + splitText[subIndex];
          }
          lastChar = splitText[index];
          if (lastChar != " ") {
            values.unshift(word);
          }
        }
      }
    }
    if (words.length > 1) {
      const splitText = name
        .toLowerCase()
        .trim()
        .split("");
      //console.log(splitText)
      let lastChar = "";
      for (let index = 0; index < splitText.length; index++) {
        if (index == 0) {
          values.unshift(splitText[index]);
        } else {
          let word = "";
          word = lastChar == " " ? "" : word;
          for (let subIndex = 0; subIndex <= index; subIndex++) {
            word = word + splitText[subIndex];
          }
          lastChar = splitText[index];
          if (lastChar != " ") {
            values.unshift(word);
          }
        }
      }
    }
    return values;
  }

  formatoMoneda(val) {
    if (val !== undefined) {
      let newValue = val;
      newValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return newValue;
    }
  }
  loadFields() {
    console.log("loadFields");
    //$('#loading').hide()
  }
  showNotification(
    type: string,
    title?: string,
    message?: string,
    timeout?: number
  ) {
    iziToast.destroy();
    switch (type) {
      case "success":
        iziToast.show({
          title: title !== undefined ? title : "Éxito",
          message:
            message !== undefined ? message : "Información cargada con éxito",
          timeout: timeout !== undefined ? timeout : 3000,
          position: "topRight",
          theme: "light",
          color: "green" // blue, red, green, yellow
        });
        break;

      case "fail":
        iziToast.show({
          title: title !== undefined ? title : "Error",
          message:
            message !== undefined
              ? message
              : "Ocurrió un error al cargar la información",
          timeout: timeout !== undefined ? timeout : 3000,
          position: "topRight",
          theme: "light",
          color: "red" // blue, red, green, yellow
        });
        break;

      case "info":
        iziToast.show({
          title: title !== undefined ? title : "Alerta",
          message: message,
          timeout: timeout !== undefined ? timeout : 3000,
          position: "topRight",
          theme: "light",
          color: "yellow" // blue, red, green, yellow
        });
        break;

      case "loading":
        iziToast.show({
          title: title !== undefined ? title : "Cargando",
          message:
            message !== undefined
              ? message
              : "Cargando información. Un momento por favor...",
          timeout: 0,
          displayMode: 0,
          theme: "dark",
          close: false,
          closeOnEscape: false,
          drag: false,
          animateInside: true,
          overlay: false,
          position: "topRight"
          //icon: 'far fa-clock',
        });
        break;

      default:
        break;
    }
  }
  sendNotification(audio) {
    audio.play();
  }
  static async getInfo() {
    console.log("getInfo");
    const docRef = doc(db, "website", "info");

    onSnapshot(docRef, doc => {
      store.state.maintenance = doc.data()!.maintenance;
      const jobs = doc.data()!.jobs;
      const codes = doc.data()!.codes;
      for (const key in jobs) {
        const job = jobs[key];
        store.state.jobsArray.push(job);
      }

      store.state.jobsArray.sort((a, b) => {
        return a.position - b.position;
      });

      store.state.jobsArray.forEach(function(item) {
        Vue.set(store.state.jobs, item.key, item);
        store.state.jobs[item.key] = item;
      });

      store.state.codes = codes;

      console.log("jobsArray");
      console.log(store.state.jobsArray);
      console.log(store.state.jobs);
    });
  }

  static async getServices() {
    console.log("getServices");
    const docRef = doc(db, "website", "data");
    const docSnap = await getDoc(docRef);
    const services = docSnap.data()!.services;
    const servicesArray: any = [];

    for (const key in services) {
      const service = services[key];
      servicesArray.push(service);
      store.state.servicesArray.push(service.key);
    }

    servicesArray.sort((a, b) => {
      return a.position - b.position;
    });

    servicesArray.forEach(function(item) {
      Vue.set(store.state.services, item.key, item);
      store.state.services[item.key] = item;
    });

    /*store.state.services = Object.keys(services).sort().reduce(
      (obj, key) => {
        obj[key] = services[key];
        return obj;
      },
      {}
    );*/
    console.log("getServices result");
    console.log(store.state.services);
    console.log(store.state.servicesArray);
  }

  static async getProjects() {
    store.state.projectsLoaded = false;
    const subColRef = collection(db, "website", "data", "projects");
    const projects = await getDocs(subColRef);
    projects.forEach(doc => {
      const project = doc.data();
      project["id"] = doc.id;
      if (project.is_featured_project) {
        store.state.featuredProjects.push(project);
      } else {
        store.state.normalProjects.push(project);
      }
      store.state.allProjects.push(project);
    });
    store.state.featuredProjects.sort((a, b) => {
      return a.priority - b.priority;
    });
    store.state.normalProjects.sort((a, b) => {
      return a.priority - b.priority;
    });
    store.state.allProjects.sort((a, b) => {
      return a.priority - b.priority;
    });
    store.state.isLoaded = true;

    console.log("Projects loaded");
    //console.log(store.state.allProjects)
    store.state.projectsLoaded = true;
  }

  getProjectsByService(service) {
    store.state.projectsByServiceLoaded = false;
    if (service === "all") {
      store.state.projectsByService = this.$store.state.allProjects;
    } else {
      store.state.projectsByService = this.$store.state.allProjects.filter(
        project => {
          return project.services.includes(service);
        }
      );
    }
    store.state.projectsByServiceLoaded = true;
    console.log("getProjectsByService finished");
    console.log(store.state.projectsByService.length);
  }

  getServiceText(text) {
    const services = text.split(",");
    let finalText = "";
    if (services.length > 1) {
      for (let index = 0; index < services.length; index++) {
        const element = services[index];
        if (index === services.length - 1) {
          finalText =
            finalText + store.state.services[element].name[store.state.lang];
        } else {
          finalText =
            finalText +
            store.state.services[element].name[store.state.lang] +
            ", ";
        }
      }
      return finalText;
    } else {
      return store.state.services[text].name[store.state.lang];
    }
  }

  getJobText(text) {
    const job = store.state.jobsArray.find(job => job.key === text);
    if (job !== undefined) {
      return job[store.state.lang];
    } else {
      return "Not found";
    }
  }

  openLink(url) {
    window.open(url, "_blank");
  }

  whatsapp() {
    let message = "";
    if (store.state.lang === "es") {
      message =
        "https://wa.me/12016925201?text=%C2%A1Hola%21+quisiera+mas+informaci%C3%B3n";
    } else {
      message = "https://wa.me/12016925201?text=%C2%A1Hi%21+i+want+more+info";
    }
    window.open(message);
  }

  async updateMedia(file, projectSlug, refToUpdate, varToUpdate) {
    this.showNotification("loading", "Subiendo media", "Un momento ...", 0);
    const storageRef = ref(
      storage,
      "/projects/" + projectSlug + "/" + file.name
    );
    const uploadTask = await uploadBytesResumable(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTask.ref);
    await updateDoc(refToUpdate, { [varToUpdate]: downloadURL })
      .then(() => {
        this.showNotification(
          "success",
          "Media actualizado",
          "Se actualizó correctamente",
          2000
        );
      })
      .catch(error => {
        console.log(error);
        this.showNotification(
          "fail",
          "Error",
          "Ocurrió un error al actualizar la imagen",
          2000
        );
      });
  }
}
