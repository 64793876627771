
import { db } from "@/main";
import mixin from "@/mixin";
import { addDoc, collection, doc, setDoc } from "@firebase/firestore";
import { getDocs, query } from "firebase/firestore";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Component
export default class BriefWeb extends mixins(mixin) {
  briefs:any = []
  questions = {
    section_1: {
      title: {
        es: "1. Información personal",
        us: "1. Personal info"
      },
      questions: {
        s1_q1: {
          title: {
            es: "¿Cómo es tu nombre?",
            us: "What is your name?"
          },
          type: "text",
          answer: ""
        },
        s1_q2: {
          title: {
            es: "¿Que rol tienes en la empresa?",
            us: "What role do you have in the company?"
          },
          type: "text",
          answer: ""
        },
        s1_q3: {
          title: {
            es: "¿Cuál es tu email?",
            us: "What is your email?"
          },
          type: "text",
          answer: ""
        },
        s1_q4: {
          title: {
            es: "¿Cuál es tu teléfono?",
            us: "What is your phone?"
          },
          type: "text",
          answer: ""
        }
      }
    },
    section_2: {
      title: {
        es: "2. Información del negocio",
        us: "2. Business info"
      },
      questions: {
        s2_q1: {
          title: {
            es: "¿Cómo se llama tu empresa?",
            us: "What's the name of your company?"
          },
          type: "text",
          answer: ""
        },
        s2_q2: {
          title: {
            es: "¿Cuál es tu negocio o a qué se dedica tu compañía?",
            us: "What is your business or what does your company do?"
          },
          type: "textarea",
          answer: ""
        },
        s2_q3: {
          title: {
            es:
              "¿Cuál es el mensaje principal que tu empresa busca transmitir?",
            us: "What is the main message that your company seeks to convey?"
          },
          type: "textarea",
          answer: ""
        },
        s2_q4: {
          title: {
            es: "¿Cuáles son tus productos o servicios destacados?",
            us: "What are your featured products or services?"
          },
          type: "textarea",
          answer: ""
        },
        s2_q5: {
          title: {
            es: "¿Cuál es el mercado objetivo con el que quieres comunicarte?",
            us: "What is the target market you want to communicate with?"
          },
          type: "textarea",
          answer: ""
        }
      }
    },
    section_3: {
      title: {
        es: "3. Competencia",
        us: "3. Competition"
      },
      questions: {
        s3_q1: {
          title: {
            es: "¿Quiénes son tus competidores?",
            us: "Who are your competitors?"
          },
          type: "text",
          answer: ""
        },
        s3_q2: {
          title: {
            es: "¿Cuáles son las direcciones web de tus competidores?",
            us: "What are the web addresses of your competitors?"
          },
          type: "text",
          answer: ""
        }
      }
    },
    section_4: {
      title: {
        es: "4. Info del sitio",
        us: "4. Web info"
      },
      questions: {
        s4_q1: {
          title: {
            es: "¿Qué información quieres destacar en tu página principal?",
            us: "What information do you want to highlight on your home page?"
          },
          type: "multiple",
          options: {
            s4_q1_sq1: {
              es: "Banner principal imágenes (Grande)",
              us: "Main banner images (Large)"
            },
            s4_q1_sq2: {
              es: "Banner principal imágenes (Pequeño)",
              us: "Main banner images (Small)"
            },
            s4_q1_sq3: {
              es: "Sección de eventos",
              us: "Events section"
            },
            s4_q1_sq4: {
              es: "Contenidos personalizados",
              us: "Personalized content"
            },
            s4_q1_sq5: {
              es: "Portafolio de servicios",
              us: "Services briefcase"
            },
            s4_q1_sq6: {
              es: "Artículos de blog",
              us: "Blog articles"
            },
            s4_q1_sq7: {
              es: "Productos destacados (Catálogo)",
              us: "Featured Products (Catalogue)"
            },
            s4_q1_sq8: {
              es: "Suscripción a boletín de novedades",
              us: "Newsletter subscription"
            },
            s4_q1_sq9: {
              es: "Widget de whatsapp",
              us: "Whatsapp widget"
            },
            s4_q1_sq10: {
              es: "Links a redes sociales",
              us: "Links to social networks"
            },
            s4_q1_sq11: {
              es: "Incrustación de redes sociales",
              us: "Social Media Embedding"
            },
            s4_q1_sq12: {
              es: "Mapa de ubicación",
              us: "Location map"
            },
            s4_q1_sq13: {
              es: "Galería de videos",
              us: "Video gallery"
            },
            s4_q1_sq14: {
              es: "Formularios",
              us: "Forms"
            },
            s4_q1_sq15: {
              es: "Encuestas",
              us: "Surveys"
            },
            s4_q1_sq16: {
              es: "Agendar citas",
              us: "Schedule appointments"
            },
            s4_q1_sq17: {
              es: "Inicio de sesión",
              us: "Login"
            },
            s4_q1_sq18: {
              es: "(Intranet, e-commerce, etc)",
              us: "(Intranet, e-commerce, etc.)"
            }
          },
          answer: []
        }
      }
    },
    section_5: {
      title: {
        es: "5. Objetivos",
        us: "5. Objectives"
      },
      questions: {
        s5_q1: {
          title: {
            es:
              "Objetivos principales de la página: (Seleccione máximo 4 opciones)",
            us: "Main objectives of the page: (Select a maximum of 4 options)"
          },
          type: "multiple",
          options: {
            s5_q1_sq1: {
              es: "Fácil accesibilidad / navegación",
              us: "Easy accessibility / navigation"
            },
            s5_q1_sq2: {
              es: "Diseño de alto impacto visual",
              us: "High visual impact design"
            },
            s5_q1_sq3: {
              es: "Diseño simple/ minimalista/ sofisticado",
              us: "Simple/minimalist/sophisticated design"
            },
            s5_q1_sq4: {
              es: "Diseño elaborado/dominante",
              us: "Elaborate/dominant design"
            },
            s5_q1_sq5: {
              es: "Sitio gráfico: Poco texto, más imagen",
              us: "Graphic site: Little text, more image"
            },
            s5_q1_sq6: {
              es: "Sitio textual: Menos imágenes, más texto",
              us: "Text site: Less images, more text"
            }
          },
          answer: []
        }
      }
    }
  };

  headersExcel = {
    "¿Cómo es tu nombre?": "section_1.questions.s1_q1.answer",
    "¿Que rol tienes en la empresa?": "section_1.questions.s1_q2.answer",
    "¿Cuál es tu email?": "section_1.questions.s1_q3.answer",
    "¿Cuál es tu teléfono?": "section_1.questions.s1_q4.answer",
    //
    "¿Cómo se llama tu empresa?": "section_2.questions.s2_q1.answer",
    "¿Cuál es tu negocio o a qué se dedica tu compañía?": "section_2.questions.s2_q2.answer",
    "¿Cuál es el mensaje principal que tu empresa busca transmitir?": "section_2.questions.s2_q3.answer",
    "¿Cuáles son tus productos o servicios destacados?": "section_2.questions.s2_q4.answer",
    "¿Cuál es el mercado objetivo con el que quieres comunicarte?": "section_2.questions.s2_q5.answer",
    //

    "¿Quiénes son tus competidores?": "section_3.questions.s3_q1.answer",
    "¿Cuáles son las direcciones web de tus competidores?": "section_3.questions.s3_q2.answer",
    //
    "¿Qué información quieres destacar en tu página principal?": "section_4.questions.s4_q1.answer",
    //
    "Objetivos principales de la página: (Seleccione máximo 4 opciones)": "section_5.questions.s5_q1.answer",
  }

  @Watch("questions.section_5.questions.s5_q1.answer")
  onAnswersChange(val) {
    console.log(val);
  }
  requiredRule: any = [];
  nameRules: any = [];
  emailRules: any = [];
  loading = false;
  showMessage = false;
  success = false;
  successMessage: string = this.$store.state.texts.contact.page.messages
    .success[this.$store.state.lang];
  failMessage: string = this.$store.state.texts.contact.page.messages.fail[
    this.$store.state.lang
  ];
  async mounted() {
    const ref = collection(db, "website", "requests", "brief_web");
    const q = query(ref);
    await getDocs(q).then((querySnapshot) => {
      this.briefs = [];
      querySnapshot.forEach((doc) => {
        this.briefs.push(doc.data());
      });
    });
    this.requiredRule = [
      v =>
        v != undefined
          ? !!v ||
            this.$store.state.texts.rules.required[this.$store.state.lang]
          : ""
    ];
    this.nameRules = [
      v =>
        v != undefined
          ? !!v || this.$store.state.texts.rules.names[this.$store.state.lang]
          : ""
    ];
    this.emailRules = [
      v =>
        !!v ||
        this.$store.state.texts.rules.email.required[this.$store.state.lang],
      v =>
        /.+@.+\..+/.test(v) ||
        this.$store.state.texts.rules.email.format[this.$store.state.lang]
    ];
  }
  @Watch("fields.interests")
  onInterestsChanges(val) {
    console.log(val);
  }

  async sendRequest() {
    if ((this.$refs.formBrief as any).validate()) {
      console.log("Correct");
      this.loading = true;
      const subColReff = collection(db, "website", "requests", "brief_web");
      await addDoc(subColReff, this.questions)
        .then(() => {
          this.success = true;
          this.loading = false;
          this.showMessage = true;
        })
        .catch(error => {
          this.success = false;
          this.loading = false;
          this.showMessage = true;
          console.log("Error contact: " + error);
        });
    } else {
      this.loading = false;
      this.showMessage = false;
      console.log("incorrect");
    }
  }
}
