
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Mixin from "../../mixin";
@Component
export default class SubTitleItem extends mixins(Mixin) {
  @Prop() title;
  @Prop() type;

  getFontWeight() {
    return "font-weight-" + this.type;
  }
}
