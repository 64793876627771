var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.$store.state.isLoaded && _vm.project_info != null)?_c('v-col',{key:"projects",staticClass:"ma-0 pa-0"},[_c('section',{staticClass:"section section-project-no-border section-100 pa-0",style:({
        'background-color': _vm.project_info.color,
        position: 'relative',
        width: '100vw',
      })},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',{class:{
            'title-project anim-right-left': _vm.project_info.color != '#fff',
            'title-project-dark  anim-right-left':
              _vm.project_info.color == '#fff',
          },style:({ 'font-size': _vm.getFontSize() })},[_vm._v(" "+_vm._s(_vm.project_info.name.toUpperCase())+" ")])]),_c('v-img',{style:({ position: 'absolute', top: '25%' }),attrs:{"max-width":_vm.maxImageWidth,"src":_vm.project_info.detail_image_comp}}),(_vm.$store.state.isEditing)?_c('v-file-input',{staticStyle:{"position":"absolute","top":"8%"},attrs:{"hint":"Modificar Imagen","solo":"","accept":"image/png, image/jpeg, image/bmp","show-size":"","counter":""},on:{"change":_vm.onSelectedDetailImageChange}}):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0 project-content"},[_c('v-col',{class:{
          'section section-80 main-page-padding title-section':
            _vm.$vuetify.breakpoint.mdAndUp,
          'section section-60 main-page-padding title-section':
            _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"align-self":"center","md":"10","cols":"12"}},[_c('h3',{staticClass:"animation first-text"},[_vm._v(" "+_vm._s(_vm.project_info.name)+" ")]),_c('h1',{staticClass:"animation second-text"},[_vm._v(" "+_vm._s(_vm.project_info.description[_vm.$store.state.lang])+" ")])]),_c('section',{class:{
          'section section-100 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
          'section pa-0 ma-0': _vm.$vuetify.breakpoint.smAndDown,
        },style:({ 'background-color': _vm.project_info.color })},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[(_vm.project_info.detail_video == '')?_c('v-col',{style:({
              'background-image': 'url(' + _vm.project_info.detail_image + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              /*'background-origin': 'content-box',*/
              height: _vm.$vuetify.breakpoint.smAndDown ? '400px' : '100%',
              width: '100%',
            }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('MainVideoComponent',{attrs:{"video":_vm.project_info.detail_video}})],1),(_vm.$store.state.isEditing)?_c('v-file-input',{attrs:{"hint":"Modificar video","label":"Modificar video","solo":"","accept":"image/png, image/jpeg, image/bmp, video/mp4, video/mov","show-size":"","counter":""},on:{"change":_vm.onSelectedVideoChange}}):_vm._e(),(_vm.$store.state.isEditing)?_c('v-file-input',{attrs:{"hint":"Modificar Imagen","label":"Modificar Imagen","solo":"","accept":"image/png, image/jpeg, image/bmp","show-size":"","counter":""},on:{"change":_vm.onSelectedMainImageChange}}):_vm._e()],1)],1),_c('v-col',{staticClass:"main-page-padding"},[_c('Challenge',{attrs:{"data":_vm.project_info.challenge,"id":_vm.project_info.id,"slug":_vm.project_info.slug}}),_c('Branding',{attrs:{"data":_vm.project_info.branding,"id":_vm.project_info.id,"slug":_vm.project_info.slug}}),_c('Apps',{attrs:{"data":_vm.project_info.apps,"id":_vm.project_info.id,"slug":_vm.project_info.slug}}),_c('Webs',{attrs:{"data":_vm.project_info.webs,"id":_vm.project_info.id,"slug":_vm.project_info.slug}}),_c('Marketing',{attrs:{"data":_vm.project_info.marketing,"id":_vm.project_info.id,"slug":_vm.project_info.slug}})],1),(_vm.next_project_slug !== null)?_c('NextProject',{class:_vm.next_project_slug,attrs:{"slug":_vm.next_project_slug,"data":_vm.project_info}}):_vm._e()],1)],1):_c('v-col',{key:"loader"},[_c('section',{class:{
        'section section-project-no-border section-100 pa-0':
          _vm.$vuetify.breakpoint.mdAndUp,
        'section section-project-no-border section-70 pa-0':
          _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"lds-roller"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }