
import { db } from "@/main";
import mixin from "@/mixin";
import { addDoc, collection, doc, getDoc, setDoc } from "@firebase/firestore";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import JsonExcel from "vue-json-excel";
import { getDocs, query } from "firebase/firestore";
Vue.component("downloadExcel", JsonExcel);
@Component
export default class BrieBrand extends mixins(mixin) {
  questions = {
    section_1: {
      title: {
        es: "1. Información personal",
        us: "1. Personal info",
      },
      questions: {
        s1_q1: {
          title: {
            es: "¿Cómo es tu nombre?",
            us: "What is your name?",
          },
          type: "text",
          answer: "",
        },
        s1_q2: {
          title: {
            es: "¿Que rol tienes en la empresa?",
            us: "What role do you have in the company?",
          },
          type: "text",
          answer: "",
        },
        s1_q3: {
          title: {
            es: "¿Cuál es tu email?",
            us: "What is your email?",
          },
          type: "text",
          answer: "",
        },
        s1_q4: {
          title: {
            es: "¿Cuál es tu teléfono?",
            us: "What is your phone?",
          },
          type: "text",
          answer: "",
        },
      },
    },
    section_2: {
      title: {
        es: "2. Información del negocio",
        us: "2. Business info",
      },
      questions: {
        s2_q1: {
          title: {
            es: "¿Cómo se llama tu empresa / marca ?",
            us: "What's the name of your company / brand?",
          },
          type: "text",
          answer: "",
        },
        s2_q2: {
          title: {
            es: "¿Cuál es tu negocio o a qué se dedica tu compañía?",
            us: "What is your business or what does your company do?",
          },
          type: "textarea",
          answer: "",
        },
        s2_q3: {
          title: {
            es: "¿Cuál es el mensaje principal que tu empresa busca transmitir?",
            us: "What is the main message that your company seeks to convey?",
          },
          type: "textarea",
          answer: "",
        },
        s2_q4: {
          title: {
            es: "¿Cuáles son tus productos o servicios destacados?",
            us: "What are your featured products or services?",
          },
          type: "textarea",
          answer: "",
        },
        s2_q5: {
          title: {
            es: "¿Cuál es el mercado objetivo con el que quieres comunicarte?",
            us: "What is the target market you want to communicate with?",
          },
          type: "textarea",
          answer: "",
        },
        s2_q6: {
          title: {
            es: "¿Qué objetivo tiene este proyecto de diseño de marca?",
            us: "What is the objective of this brand design project?",
          },
          type: "textarea",
          answer: "",
        },
      },
    },
    section_3: {
      title: {
        es: "3. Competencia",
        us: "3. Competition",
      },
      questions: {
        s3_q1: {
          title: {
            es: "¿Quiénes son tus competidores?",
            us: "Who are your competitors?",
          },
          type: "text",
          answer: "",
        },
        s3_q2: {
          title: {
            es: "¿Qué te diferencia de la competencia?",
            us: "What differentiates you from the competition?",
          },
          type: "text",
          answer: "",
        },
        s3_q3: {
          title: {
            es: "¿Cuáles son las direcciones web de tus competidores?",
            us: "What are the web addresses of your competitors?",
          },
          type: "text",
          answer: "",
        },
      },
    },
    section_4: {
      title: {
        es: "4. Consumidor",
        us: "4. Client",
      },
      questions: {
        s3_q1: {
          title: {
            es: "¿Cuáles son los beneficios que le brindas a tu consumidor?",
            us: "What are the benefits you provide to your consumer?",
          },
          type: "textarea",
          answer: "",
        },
        s3_q2: {
          title: {
            es: "Qué quieres que tu consumidor",
            us: "What do you want your consumer",
          },
          type: "columns",
          options: {
            s3_sq1: {
              es: "Sepa",
              us: "Know",
              answer: "",
            },
            s3_sq2: {
              es: "Haga",
              us: "Do",
              answer: "",
            },
            s3_sq3: {
              es: "Sienta",
              us: "Feel",
              answer: "",
            },
            s3_sq4: {
              es: "Piense",
              us: "Think",
              answer: "",
            },
          },
        },
        s3_q3: {
          title: {
            es: "¿Cómo vas a posicionar la marca?",
            us: "How are you going to position the brand?",
          },
          type: "textarea",
          answer: "",
        },
        s3_q4: {
          title: {
            es: "¿Cuáles son los valores de tu marca?",
            us: "What are your brand values?",
          },
          type: "textarea",
          answer: "",
        },
        s3_q5: {
          title: {
            es: "¿Cuáles son las razones para creer en tu marca?",
            us: "What are the reasons to believe in your brand?",
          },
          type: "textarea",
          answer: "",
        },
      },
    },
    section_5: {
      title: {
        es: "5. Tipografía",
        us: "5. Typography",
      },
      questions: {
        s5_q1: {
          title: {
            es: "¿Cuál de estas tipografías te gustaría más?",
            us: "Which of these fonts would you like the most?",
          },
          type: "multiple-font",
          options: {
            s5_q1_sq1: {
              value: "Serif - Times New Roman",
              font: "Times New Roman",
            },
            s5_q1_sq2: {
              value: "Serif - Georgia",
              font: "Georgia",
            },
            s5_q1_sq3: {
              value: "Serif - Garamond",
              font: "Garamond",
            },
            s5_q1_sq4: {
              value: "Sans-serif - Arial",
              font: "Arial",
            },
            s5_q1_sq5: {
              value: "Sans-serif - Verdana",
              font: "Verdana",
            },
            s5_q1_sq6: {
              value: "Sans-serif - Helvetica",
              font: "Helvetica",
            },
            s5_q1_sq7: {
              value: "Monospace - Courier New",
              font: "Courier New",
            },
            s5_q1_sq8: {
              value: "Monospace - Lucida Console",
              font: "Lucida Console",
            },
            s5_q1_sq9: {
              value: "Monospace - Monaco",
              font: "Monaco",
            },
            s5_q1_sq10: {
              value: "Cursive - Brush Script MT",
              font: "Brush Script MT",
            },
            s5_q1_sq11: {
              value: "Cursive - Lucida Handwriting",
              font: "Lucida Handwriting",
            },
            s5_q1_sq12: {
              value: "Fantasy - Copperplate",
              font: "Copperplate",
            },
            s5_q1_sq13: {
              value: "Fantasy - Papyrus",
              font: "Papyrus",
            },
          },
          answer: [],
        },
        s5_q2: {
          title: {
            es: "¿Qué colores te gustaría en tu marca y por qué?",
            us: "What colors would you like in your brand and why?",
          },
          type: "textarea",
          answer: "",
        },
        s5_q3: {
          title: {
            es: "¿Cuáles de estas palabras definen mejor lo que imaginas en tu marca?",
            us: "Which of these words best define what you envision in your brand?",
          },
          type: "multiple",
          options: {
            s5_q3_sq1: {
              es: "Minimalista",
              us: "Minimalist",
            },
            s5_q3_sq2: {
              es: "Elegante",
              us: "Elegant",
            },
            s5_q3_sq3: {
              es: "Fresco",
              us: "Fresh",
            },
            s5_q3_sq4: {
              es: "Juvenil",
              us: "Youth",
            },
            s5_q3_sq5: {
              es: "Colorido",
              us: "Colorful",
            },
            s5_q3_sq6: {
              es: "Brillante",
              us: "Sparkly",
            },
            s5_q3_sq7: {
              es: "Sobrio",
              us: "Sober",
            },
            s5_q3_sq8: {
              es: "Oscuro",
              us: "Dark",
            },
            s5_q3_sq9: {
              es: "Balanceado",
              us: "Balanced",
            },
            s5_q3_sq10: {
              es: "Floral",
              us: "Floral",
            },
            s5_q3_sq11: {
              es: "Opaco",
              us: "Opaque",
            },
            s5_q3_sq12: {
              es: "Ilustraciones",
              us: "Illustrations",
            },
            s5_q3_sq13: {
              es: "Vintage",
              us: "Vintage",
            },
            s5_q3_sq14: {
              es: "Natural",
              us: "Natural",
            },
            s5_q3_sq15: {
              es: "Trendy",
              us: "Trendy",
            },
            s5_q3_sq16: {
              es: "Masculino",
              us: "Male",
            },
            s5_q3_sq17: {
              es: "Femenino",
              us: "Female",
            },
            s5_q3_sq18: {
              es: "Ambos",
              us: "Both",
            },
          },
          answer: [],
        },
      },
    },
  };

  headersExcel = {
    "¿Cómo es tu nombre?": "section_1.questions.s1_q1.answer",
    "¿Que rol tienes en la empresa?": "section_1.questions.s1_q2.answer",
    "¿Cuál es tu email?": "section_1.questions.s1_q3.answer",
    "¿Cuál es tu teléfono?": "section_1.questions.s1_q4.answer",
    //
    "¿Cómo se llama tu empresa / marca ?": "section_2.questions.s2_q1.answer",
    "¿Cuál es tu negocio o a qué se dedica tu compañía?":
      "section_2.questions.s2_q2.answer",
    "¿Cuál es el mensaje principal que tu empresa busca transmitir?":
      "section_2.questions.s2_q3.answer",
    "¿Cuáles son tus productos o servicios destacados?":
      "section_2.questions.s2_q4.answer",
    "¿Cuál es el mercado objetivo con el que quieres comunicarte?":
      "section_2.questions.s2_q5.answer",
    "¿Qué objetivo tiene este proyecto de diseño de marca?":
      "section_2.questions.s2_q6.answer",
    //
    "¿Quiénes son tus competidores?": "section_3.questions.s3_q1.answer",
    "¿Qué te diferencia de la competencia?": "section_3.questions.s3_q2.answer",
    "¿Cuáles son las direcciones web de tus competidores?":
      "section_3.questions.s3_q3.answer",
    //
    "¿Cuáles son los beneficios que le brindas a tu consumidor?":
      "section_4.questions.s3_q1.answer",
    "Qué quieres que tu consumidor - Sepa":
      "section_4.questions.s3_q2.options.s3_sq1",
    "Qué quieres que tu consumidor - Haga":
      "section_4.questions.s3_q2.options.s3_sq2",
    "Qué quieres que tu consumidor - Sienta":
      "section_4.questions.s3_q2.options.s3_sq3",
    "Qué quieres que tu consumidor - Piense":
      "section_4.questions.s3_q2.options.s3_sq4",
    "¿Cómo vas a posicionar la marca?": "section_4.questions.s3_q3.answer",
    "¿Cuáles son los valores de tu marca?": "section_4.questions.s3_q4.answer",
    "¿Cuáles son las razones para creer en tu marca?":
      "section_4.questions.s3_q5.answer",
    //
    "¿Cuál de estas tipografías te gustaría más?":
      "section_5.questions.s5_q1.answer",
    "¿Qué colores te gustaría en tu marca y por qué?":
      "section_5.questions.s5_q2.answer",
    "¿Cuáles de estas palabras definen mejor lo que imaginas en tu marca?":
      "section_5.questions.s5_q3.answer",
  };

  @Watch("questions.section_5.questions.s5_q1.answer")
  onAnswersChange(val) {
    console.log(val);
  }
  requiredRule: any = [];
  nameRules: any = [];
  emailRules: any = [];
  loading = false;
  showMessage = false;
  success = false;
  successMessage: string =
    this.$store.state.texts.contact.page.messages.success[
      this.$store.state.lang
    ];
  failMessage: string =
    this.$store.state.texts.contact.page.messages.fail[this.$store.state.lang];
  briefs: any = [];
  async mounted() {
    const ref = collection(db, "website", "requests", "brief_brand");
    const q = query(ref);
    await getDocs(q).then((querySnapshot) => {
      this.briefs = [];
      querySnapshot.forEach((doc) => {
        this.briefs.push(doc.data());
      });
    });
    this.requiredRule = [
      (v) =>
        v != undefined
          ? !!v ||
            this.$store.state.texts.rules.required[this.$store.state.lang]
          : "",
    ];
    this.nameRules = [
      (v) =>
        v != undefined
          ? !!v || this.$store.state.texts.rules.names[this.$store.state.lang]
          : "",
    ];
    this.emailRules = [
      (v) =>
        !!v ||
        this.$store.state.texts.rules.email.required[this.$store.state.lang],
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.$store.state.texts.rules.email.format[this.$store.state.lang],
    ];
  }
  @Watch("fields.interests")
  onInterestsChanges(val) {
    console.log(val);
  }

  async sendRequest() {
    if ((this.$refs.formBrief as any).validate()) {
      console.log("Correct");
      this.loading = true;
      const subColReff = collection(db, "website", "requests", "brief_brand");
      await addDoc(subColReff, this.questions)
        .then(() => {
          this.success = true;
          this.loading = false;
          this.showMessage = true;
        })
        .catch((error) => {
          this.success = false;
          this.loading = false;
          this.showMessage = true;
          console.log("Error contact: " + error);
        });
    } else {
      this.loading = false;
      this.showMessage = false;
      console.log("incorrect");
    }
  }
}
