
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
@Component
export default class Alert extends mixins(mixin) {

  mounted(){
    console.log("Mounter alert")
  }

  goToContactCode() {
    console.log("goToContactCode")
    this.$store.state.showSCSDialog = false
    this.$router.push({
      name: "contact-code",
      
      //target: "_blank"
    });
  }
}

