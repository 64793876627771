import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

// Translation provided by Vuetify (typescript)
import es from "vuetify/src/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#1A1A1A",
        titles: colors.shades.black,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: "#F4F5FA"
      },
      dark: {
        primary: colors.shades.white,
        titles: "#D8DA00",
        accent: "#1A1A1A",
        background: "#F4F5FA"
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: "mdi" || "fa" || "md" // default - only for display purposes
  },
  lang: {
    locales: { es },
    current: "es"
  }
});
