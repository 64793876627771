
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ButtonOutlined from "@/components/general/ButtonOutlined.vue";
@Component({
  components: {
    ButtonOutlined
  }
})
export default class NextProject extends mixins(mixin) {
  @Prop() data;
  @Prop() slug;
}
