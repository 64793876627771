
import { db, storage } from "@/main";
import mixin from "@/mixin";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TitleItem from "@/components/general/TitleItem.vue";
import SubTitleItem from "@/components/general/SubTitleItem.vue";
@Component({
  components: {
    TitleItem,
    SubTitleItem
  }
})
export default class Marketing extends mixins(mixin) {
  @Prop() data;
  @Prop() id;
  @Prop() slug;
  marketing = {
    art: {
      "1": "",
      "2": "",
      "3": ""
    },
    text: {
      es: "",
      us: ""
    }
  };
  selectedMedia: File = new File([""], "");
  mounted() {
    this.marketing.text.es = this.data.text.es;
    this.marketing.text.us = this.data.text.us;
    this.marketing.art = this.data.art;
  }

  onSelectedMarketingChange(val) {
    console.log(val);
    console.log(val[0]);
    this.selectedMedia = val;
  }

  async updateInfo() {
    this.showNotification("loading", "Subiendo media", "Un momento ...", 0);
    //MEDIA SERVICES
    if (this.selectedMedia != null && this.selectedMedia != undefined) {
      for (let index = 0; index < (this.selectedMedia as any).length; index++) {
        const media = this.selectedMedia[index];
        const storageRef = ref(
          storage,
          "/projects/" + this.slug + "/marketing/" + media.name
        );
        const uploadTask = await uploadBytesResumable(storageRef, media);
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.marketing.art[index + 1] = downloadURL;
      }
    }
    console.log(this.id);
    console.log(this.marketing);
    this.showNotification("loading", "Actualizando info", "Un momento ...", 0);

    await updateDoc(doc(db, "website", "data", "projects", this.id), {
      marketing: this.marketing
    })
      .then(async () => {
        this.showNotification(
          "success",
          "Info agregada",
          "La info fue agregada con éxito."
        );
      })
      .catch(error => {
        console.log(error);
        this.showNotification("fail", "Error", "Error al actualizar la info");
        console.log(error);
      });
  }
}
