
import { db } from "@/main";
import mixin from "@/mixin";
import { addDoc, collection, doc, getDocs, setDoc } from "@firebase/firestore";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Component
export default class Jobs extends mixins(mixin) {
  loading = false;
  showMessage = false;
  success = false;

  async mounted() {
    this.$store.state.vacancies = [];
    const subColRef = collection(db, "website", "requests", "vacancies");
    const vacancies = await getDocs(subColRef);
    vacancies.forEach(doc => {
      const vacancy = doc.data();
      vacancy["id"] = doc.id;
      this.$store.state.vacancies.push(vacancy);
    });
    this.$store.state.vacancies.sort((a, b) => {
      return a.creation_date.toDate() - b.creation_date.toDate();
    });
  }

  goToJob(id) {
    this.$router.push({
      name: "job-details",
      params: {
        id: id
        /*name: this.name,
        slug: id,*/
      }
      //target: "_blank"
    });
  }
}
