
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TitleItem from "@/components/general/TitleItem.vue";
import SubTitleItem from "@/components/general/SubTitleItem.vue";
import axios from "axios";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import { db, storage } from "@/main";
@Component({
  components: {
    TitleItem,
    SubTitleItem
  }
})
export default class Challenge extends mixins(mixin) {
  @Prop() data;
  @Prop() id;
  @Prop() slug;
  challenge = {
    art: {
      "1": "",
      "2": "",
      "3": ""
    },
    text: {
      es: "",
      us: ""
    }
  };
  selectedMedia: File = new File([""], "");
  mounted() {
    this.challenge.text.es = this.data.text.es;
    this.challenge.text.us = this.data.text.us;
    this.challenge.art = this.data.art;
  }

  onSelectedChallengeChange(val) {
    console.log(val);
    console.log(val[0]);
    this.selectedMedia = val;
  }

  async updateInfo() {
    this.showNotification("loading", "Subiendo media", "Un momento ...", 0);
    //MEDIA SERVICES
    if (this.selectedMedia != null && this.selectedMedia != undefined) {
      for (let index = 0; index < (this.selectedMedia as any).length; index++) {
        const media = this.selectedMedia[index];
        console.log(media);
        const storageRef = ref(
          storage,
          "/projects/" + this.slug + "/challenge/" + media.name
        );
        const uploadTask = await uploadBytesResumable(storageRef, media);
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.challenge.art[index + 1] = downloadURL;
      }
    }
    console.log(this.id);
    console.log(this.challenge);
    this.showNotification("loading", "Actualizando info", "Un momento ...", 0);

    await updateDoc(doc(db, "website", "data", "projects", this.id), {
      challenge: this.challenge
    })
      .then(async () => {
        this.showNotification(
          "success",
          "Info agregada",
          "La info fue agregada con éxito."
        );
      })
      .catch(error => {
        console.log(error);
        this.showNotification("fail", "Error", "Error al actualizar la info");
        console.log(error);
      });
  }
}
