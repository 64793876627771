// Your web app's Firebase configuration
import store from "../store/index";
import firebase from "firebase/compat/app";
import "firebase/messaging";
//let TOKEN_FINAL = ""
const isEditing = true;

const firebaseConfigProduction = {
  apiKey: "AIzaSyBJSo8C05gMhpElwcJnLIJHJ5AWP86jQII",
  authDomain: "digital-exp.firebaseapp.com",
  projectId: "digital-exp",
  storageBucket: "digital-exp.appspot.com",
  messagingSenderId: "123816857827",
  appId: "1:123816857827:web:f7da52c595f89d5898f5d6",
  measurementId: "G-DHT5SD0RMJ"
};

// Your web app's Firebase configuration TEST
const firebaseConfigTesting = {
  // apiKey: "AIzaSyAYwaYdIot-y6lUYTTlld5NGsjcyzdq1y4",
  // authDomain: "bartending-test.firebaseapp.com",
  projectId: "Digital Exp Test"
  // storageBucket: "bartending-test.appspot.com",
  // messagingSenderId: "592315515131",
  // appId: "1:592315515131:web:051678f95bbd975ee3b05e",
  // measurementId: "G-1LWG80LLX0"
};
const selectedProject = isEditing
  ? firebaseConfigProduction
  : firebaseConfigTesting;
const firebaseapp = firebase.initializeApp(selectedProject);
console.log("UID: " + store.state.user.uid);

const URL_PROJECT = "https://us-central1-digital-exp.cloudfunctions.net/";
const URL_PROJECT_TEST =
  "https://us-central1-digital-exp-test.cloudfunctions.net/";

const PUSH_KEY_PRODUCTION =
  "AAAAkZ3yTIY:APA91bGaf70zO2sII24ACuhDMdEqEW-czSHKfIyNoK0O0goDeucfq2zXZEfur445l5I8Yh2r9csCS9u3h23vbhSg7_5sQ1NXcdO1uPVLG6TV1et8LybsPOrPpRgmgosdTPCcqFO5c0KW";
const PUSH_KEY_TEST =
  "AAAAYKJ5ePo:APA91bE8J7tv2ftBAYTLxisjd478duaHKdPyepjZSUlNAm38uiNr0BUS2MvgIMhVsmN3UUL-ji1pJ4HGJR5oA9UPBKdQ0qfXrQEN4M8TP_tVUHufDCCbHAWxLbxPetGlGzq_3GmJNE4I";

//Key OneSignal
const KEY_MESSAGES_DIGITAL = "";

let URL_CLOUD_FUNCTIONS: string;
let KEY_MESSAGES: string;
let TOKEN_PUSH: string;
let PROJECT_NAME: string;
let PUSH_KEY: string;

if (selectedProject.projectId === "__________") {
  URL_CLOUD_FUNCTIONS = URL_PROJECT;
  KEY_MESSAGES = KEY_MESSAGES_DIGITAL;
  //TOKEN_PUSH = TOKEN_FINAL
  PROJECT_NAME = "NAME PROEJCT";
  PUSH_KEY = PUSH_KEY_PRODUCTION;
} else {
  URL_CLOUD_FUNCTIONS = URL_PROJECT_TEST;
  KEY_MESSAGES = KEY_MESSAGES_DIGITAL;
  //TOKEN_PUSH = TOKEN_FINAL
  PROJECT_NAME = "NAME PROEJCT TEST";
  PUSH_KEY = PUSH_KEY_TEST;
}

const PROJECT_ID = selectedProject.projectId;
console.log("EXPORT");
export {
  URL_CLOUD_FUNCTIONS,
  KEY_MESSAGES,
  TOKEN_PUSH,
  PROJECT_NAME,
  PROJECT_ID,
  /*TOKEN_FINAL*/ PUSH_KEY
};
export default firebaseapp;
