var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',[(_vm.data.text['es'] !== '')?_c('section',{staticClass:"section"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 pb-4",attrs:{"md":"8","cols":"12"}},[_c('h1',{staticClass:"title-text animation"},[_vm._v(" "+_vm._s(_vm.getServiceText("apps"))+" ")]),_c('p',{staticClass:"content-text my-6 animation",domProps:{"innerHTML":_vm._s(_vm.data.text[this.$store.state.lang])}})]),_c('v-col',{attrs:{"md":"4","cols":"12"}}),_c('v-row',{staticClass:"ma-0 pa-0 anim-art",attrs:{"cols":"12","md":"12"}},_vm._l((_vm.data.art),function(value,key,index){return _c('v-col',{key:index,class:{
              'ma-0 px-0 py-8 pl-8':
                (index + 1) % 2 == 0 && _vm.$vuetify.breakpoint.mdAndUp,
              'ma-0 px-0 py-8 pr-8':
                (index + 1) % 2 != 0 && _vm.$vuetify.breakpoint.mdAndUp
            },attrs:{"cols":"12","md":"6"}},[(!_vm.data.art[index + 1].includes('.mp4'))?_c('v-col',{staticClass:"ma-0 pa-2 px-0 mx-0",style:({
                'background-image': 'url(' + _vm.data.art[key] + ')',
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-position': 'top center',
                //'background-position': '0% '+$store.state.scrollValue*0.1+'px',
                height: _vm.$vuetify.breakpoint.smAndDown ? '500px' : '90vh'
              }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"video-wrapper",style:({
                  height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
                  width: '100%'
                })},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo","height":_vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px'},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.art[index + 1],"type":"video/mp4"}})])])])],1)}),1)],1)],1)],1):_vm._e(),(_vm.$store.state.isEditing)?_c('v-col',{attrs:{"cols":"12"}},[_c('TitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Apps"}}),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Español"}}),_c('v-textarea',{attrs:{"solo":"","rows":"2","required":""},model:{value:(_vm.apps.text.es),callback:function ($$v) {_vm.$set(_vm.apps.text, "es", $$v)},expression:"apps.text.es"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Inglés"}}),_c('v-textarea',{attrs:{"solo":"","rows":"2","required":""},model:{value:(_vm.apps.text.us),callback:function ($$v) {_vm.$set(_vm.apps.text, "us", $$v)},expression:"apps.text.us"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Multimedia apps"}}),_c('v-file-input',{attrs:{"solo":"","multiple":"","accept":"image/*, video/*","show-size":"","counter":"","required":""},on:{"change":_vm.onSelectedAppsChange}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{on:{"click":_vm.updateInfo}},[_vm._v("Añadir información")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }