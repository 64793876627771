
import Vue from "vue";
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import LottiePlayer from "@lottiefiles/lottie-player";
import { Watch } from "vue-property-decorator";
import ButtonColor from "@/components/header/ButtonColor.vue";
import LocomotiveScroll from "locomotive-scroll";
import {
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "@/main";
@Component({
  components: {
    LottiePlayer,
    ButtonColor,
    LocomotiveScroll
  },
})
export default class Contact extends mixins(mixin) {
  logos: Array<any> = [];
  projectsImages: Record<any, any> = { "1" : [],"2" : [],"3" : [],"4" : []}
  isHover = false;
  isProjectHover = false;
  currentProjectIndex = 0;
  tabPlan = 0;
  async mounted() {
    this.$vuetify.theme.dark = false;
    setTimeout(() => {
      this.$store.state.menuDark = true;
      this.$store.state.showFooter = false;
    }, 100);
    document.documentElement.style.setProperty(
      "--menu-color",
      this.$vuetify.theme.dark ? "#D8DA00" : "#1A1A1A"
    );
    const q = doc(db, "website", "landing-design");
    onSnapshot(q, (doc) => {
      console.log(doc.data());
      this.$store.state.texts.landings.design.sections[7].projects =
        doc.data()?.projects;

      this.$store.state.texts.landings.design.sections[9].plans =
        doc.data()?.plans;

      this.$store.state.texts.landings.design.sections[10].questions =
        doc.data()?.questions;

      this.logos = doc.data()?.logos;
      const projectsImagesList = doc
        .data()
        ?.projects_images.sort((a, b) => 0.5 - Math.random());
      console.log(projectsImagesList)
      this.projectsImages = { "1": [], "2": [], "3": [],"4" : [] };
      for (let index = 0; index < projectsImagesList.length; index++) {
        const element = projectsImagesList[index];
        if (index < 10) {
          this.projectsImages["1"].push(element);
        } else if (index < 21) {
          this.projectsImages["2"].push(element)
        } else if (index < 31) {
          this.projectsImages["3"].push(element)
        } else {
          this.projectsImages["4"].push(element)
        }
      }

    });

    /*const newProjectRef = doc(db, "website", "design-service");
    await updateDoc(
      newProjectRef,
      {
        'projects': this.$store.state.texts.landings.design.sections[7].projects,
      }
    )
      .then(async () => {
        this.showNotification(
          "success",
          "Proyectos agregados",
          "El Proyecto fue agregado con éxito."
        );
        //this.selectedImage = new File([""], "");
      })
      .catch((error) => {
        console.log(error);
        this.showNotification("fail", "Error", "Error al agregar los planes");
        console.log(error);
      });*/

    const player = document.querySelector("lottie-player");
    console.log("player");
    console.log(player);
    player!.addEventListener("hover", (e) => {
      console.log(e);
    });
  }

  /*@Watch("$store.state.scrollbar")
  onScrollBarChange(val){
    console.log("onScrollBarChange")
    console.log(val)
    val.Init();
  }*/

  onHoverAnim(id) {
    console.log("onHoverAnim");
    const element = document.getElementById(id);
    console.log(element);
    (element as any).play();
  }
  onHoverOutAnim(id) {
    console.log("onHoverOutAnim");
    const element = document.getElementById(id);
    //(element as any).stop();
    //element?.setAttribute("direction", "-1")
    (element as any).pause();
    console.log(element);
  }

  unmounted() {
    console.log("unmounted landing design");
  }

  hoverProjectChange(val, index) {
    this.isProjectHover = val;
    this.currentProjectIndex = index;
  }

  @Watch("isHover")
  onIsHoverChange(val) {
    console.log(val);
  }
}
