
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import ProjectImage from "@/components/projects/ProjectImage.vue";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/general/Loader.vue";

@Component({
  components: {
    ProjectImage,
    Loader,
  },
})
export default class Home extends mixins(mixin) {
  updated() {
    console.log("Updated");
  }
  mounted() {
    console.log(this.$store.state.showFooter)
    this.$vuetify.theme.dark = true
    this.$store.state.projectClicked = false;
    if (this.$store.state.allProjects.length > 0) {
      this.startListenHover();
    }
  }

  startListenHover() {
    setTimeout(() => {
      const apps = document.querySelector(".apps");
      const webs = document.querySelector(".webs");
      const branding = document.querySelector(".branding");
      const marketing = document.querySelector(".marketing");
      console.log(this.$store.state.cursor);
      if (
        this.$store.state.cursor !== null 
      ) {
        apps!.addEventListener("mouseenter", () => {
          if (!this.$store.state.projectClicked) {
            this.$store.state.cursor.setVideo("/videos/apps-digitalexp.mp4");
          }
        });

        apps!.addEventListener("mouseleave", () => {
          this.$store.state.cursor.show();
          this.$store.state.cursor.removeVideo();
        });

        apps!.addEventListener("click", () => {
          //this.$store.state.cursor.removeText();
          this.$store.state.cursor.removeVideo();
          this.$store.state.projectClicked = true;
        });

        webs!.addEventListener("mouseenter", () => {
          if (!this.$store.state.projectClicked) {
            this.$store.state.cursor.setSkewing(3);
            this.$store.state.cursor.setVideo(
              "/videos/apps-webs-digitalexp.mp4"
            );
          }
        });

        webs!.addEventListener("mouseleave", () => {
          //this.$store.state.cursor.removeText();
          this.$store.state.cursor.show();
          this.$store.state.cursor.removeSkewing();
          this.$store.state.cursor.removeVideo();
        });

        webs!.addEventListener("click", () => {
          //this.$store.state.cursor.removeText();
          this.$store.state.cursor.removeVideo();
          this.$store.state.projectClicked = true;
        });

        branding!.addEventListener("mouseenter", () => {
          //this.$store.state.cursor.setText("Hello!");
          if (!this.$store.state.projectClicked) {
            this.$store.state.cursor.setSkewing(3);
            this.$store.state.cursor.setVideo(
              "/videos/digitalexp-branding.mp4"
            );
          }
        });

        branding!.addEventListener("mouseleave", () => {
          //this.$store.state.cursor.removeText();
          this.$store.state.cursor.show();
          this.$store.state.cursor.removeSkewing();
          this.$store.state.cursor.removeVideo();
        });

        branding!.addEventListener("click", () => {
          //this.$store.state.cursor.removeText();
          this.$store.state.cursor.removeVideo();
          this.$store.state.projectClicked = true;
        });
      }
    }, 100);
  }

  @Watch("$store.state.projectsLoaded")
  onProjectsLoaded(val) {
    console.log("watching projectsLoaded: " + val);
    if (val) {
      this.startListenHover();
    }
  }
}
