
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class Project extends mixins(mixin) {
  @Prop() id;
  @Prop() name;
  @Prop() slug;
  @Prop() description;
  @Prop() services;
  @Prop() image;
  @Prop() year;
  @Prop() allProjectInfo;
  @Prop() projectIndex;

  goToProjectDetail() {
    console.log(this.id);
    if (this.$store.state.cursor !== null) {
      this.$store.state.cursor.hide();
    }
    this.$store.state.sortedProjects = [...this.$store.state.allProjects];
    this.$store.state.sortedProjects.splice(this.projectIndex, 1);
    this.$store.state.sortedProjects.unshift(this.allProjectInfo);
    this.$store.state.isLoaded = false;
    this.$router.push({
      name: "project-details",
      params: {
        /*id: this.id,
        name: this.name,*/
        slug: this.slug
      }
      //target: "_blank"
    });
    /*
    console.log(routeData)
    window.open(routeData.href, "_blank");*/
  }
}
