
import Mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ButtonOutlined from "@/components/general/ButtonOutlined.vue";
@Component({
  components: {
    ButtonOutlined
  }
})
export default class MainContentComponent extends mixins(Mixin) {
  @Prop() name;
  @Prop() description;
  @Prop() serviceKey;
}
