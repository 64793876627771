var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.$store.state.isLoaded && _vm.service_info != null)?_c('v-col',{key:"projects",staticClass:"ma-0 pa-0"},[_c('section',{class:{
        'section section-project-no-border section-90 pa-0':
          _vm.$vuetify.breakpoint.mdAndUp,
        'section section-project-no-border section-70 pa-0':
          _vm.$vuetify.breakpoint.smAndDown
      }},[_c('v-col',{staticClass:"section main-page-padding",attrs:{"md":"12","cols":"12"}},[_c('h1',{staticClass:"animation-fadeInDown second-text main-title-section"},[_vm._v(" "+_vm._s(_vm.service_info.name[_vm.$store.state.lang])+" ")])])],1),_c('section',{class:{
        'section section-project-no-border section-100 pa-0':
          _vm.$vuetify.breakpoint.mdAndUp,
        'section section-project-no-border section-70 pa-0':
          _vm.$vuetify.breakpoint.smAndDown
      },style:({ 'background-color': '#141D51' })},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"video-wrapper",style:({
            height: _vm.$vuetify.breakpoint.smAndDown ? '72vh' : '100vh',
            width: '100%'
          })},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo","height":_vm.$vuetify.breakpoint.smAndDown ? '72vh' : '700px'},domProps:{"muted":true}},[_c('source',{attrs:{"src":require('@/assets/videos/apps-digitalexp.mp4'),"type":"video/mp4"}})])])])],1),_c('v-col',{staticClass:"main-page-padding title-section",attrs:{"md":"10","cols":"12"}},[_c('h1',{staticClass:"animation first-text"},[_vm._v(" "+_vm._s(_vm.service_info.name[_vm.$store.state.lang])+" ")]),_c('p',{staticClass:"mt-8"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.service_info.description[_vm.$store.state.lang])}})])]),_c('v-col',{staticClass:"main-page-padding title-section"},[_c('h1',{staticClass:"left"},[_vm._v(" "+_vm._s(_vm.$store.state.texts.service_details.work[_vm.$store.state.lang])+" ")]),_vm._l((_vm.projects),function(project,index){return _c('v-col',{key:index},[_c('ProjectImage',{attrs:{"id":project.id,"name":project.name,"slug":project.slug,"description":project.description[_vm.$store.state.lang],"services":_vm.getServiceText(project.services.toString()),"allProjectInfo":project,"image":project.main_image,"year":project.year}})],1)})],2)],1):_c('v-col',{key:"loader"},[_c('section',{class:{
        'section section-project-no-border section-100 pa-0':
          _vm.$vuetify.breakpoint.mdAndUp,
        'section section-project-no-border section-70 pa-0':
          _vm.$vuetify.breakpoint.smAndDown
      }},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"lds-roller"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }