
import Mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MainImageComponent from "./mainImageComponent.vue";
import MainVideoComponent from "./mainVideoComponent.vue";
@Component({
  components: {
    MainImageComponent,
    MainVideoComponent
  }
})
export default class MainMediaComponent extends mixins(Mixin) {
  @Prop() image;
  @Prop() video;
}
