var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',[(_vm.data.text['es'] !== '')?_c('section',{staticClass:"section"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 pb-4",attrs:{"md":"8","cols":"12"}},[_c('h1',{staticClass:"title-text animation",domProps:{"innerHTML":_vm._s(this.$store.state.texts.challenge[this.$store.state.lang])}}),_c('p',{staticClass:"content-text my-6 animation",domProps:{"innerHTML":_vm._s(_vm.data.text[this.$store.state.lang])}})]),_c('v-col',{attrs:{"md":"4","cols":"12"}}),(_vm.data.art['1'] !== undefined)?_c('v-col',{staticClass:"ma-0 ml-0 pl-0 anim-art",attrs:{"cols":"6","md":"7"}},[(!_vm.data.art['1'].includes('.mp4'))?_c('v-col',{class:{
              'ma-0 pl-0 pr-7 py-0': _vm.$vuetify.breakpoint.mdAndUp,
              'ma-0 pl-0 py-0': _vm.$vuetify.breakpoint.smAndDown
            },style:({
              'background-image': 'url(' + _vm.data.art['1'] + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              'background-origin': 'content-box',
              height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
              width: '100%'
            }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"video-wrapper",style:({
                height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
                width: '100%'
              })},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo","height":_vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px'},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.art['1'],"type":"video/mp4"}})])])])],1):_vm._e(),(_vm.data.art['2'] !== undefined)?_c('v-col',{staticClass:"mr-0 pr-0 anim-art",attrs:{"cols":"6","md":"5"}},[(!_vm.data.art['2'].includes('.mp4'))?_c('v-col',{class:{
              'ma-0 px-7 py-0': _vm.$vuetify.breakpoint.mdAndUp,
              'ma-0 px-3 py-0': _vm.$vuetify.breakpoint.smAndDown
            },style:({
              'background-image': 'url(' + _vm.data.art['2'] + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              'background-origin': 'content-box',
              height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
              width: '100%'
            }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"video-wrapper",style:({
                height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px',
                width: '100%'
              })},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo","height":_vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px'},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.art['2'],"type":"video/mp4"}})])])])],1):_vm._e(),(_vm.data.art['3'] !== '')?_c('v-col',{staticClass:"ma-0 pa-0 mt-12 anim-art",attrs:{"cols":"12","md":"12"}},[(!_vm.data.art['3'].includes('.mp4'))?_c('v-col',{staticClass:"ma-0 pa-0",style:({
              'background-image': 'url(' + _vm.data.art['3'] + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              'background-origin': 'content-box',
              padding: '0',
              height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '900px',
              width: '100%'
            }),attrs:{"cols":"12","md":"12"}}):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"video-wrapper",style:({
                height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : '900px',
                width: '100%'
              })},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo","height":_vm.$vuetify.breakpoint.smAndDown ? '300px' : '700px'},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.art['3'],"type":"video/mp4"}})])])])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.state.isEditing)?_c('v-col',{attrs:{"cols":"12"}},[_c('TitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Reto"}}),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Español"}}),_c('v-textarea',{attrs:{"solo":"","rows":"2","required":""},model:{value:(_vm.challenge.text.es),callback:function ($$v) {_vm.$set(_vm.challenge.text, "es", $$v)},expression:"challenge.text.es"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Inglés"}}),_c('v-textarea',{attrs:{"solo":"","rows":"2","required":""},model:{value:(_vm.challenge.text.us),callback:function ($$v) {_vm.$set(_vm.challenge.text, "us", $$v)},expression:"challenge.text.us"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('SubTitleItem',{staticClass:"mt-3 mb-1",attrs:{"title":"Multimedia Reto"}}),_c('v-file-input',{attrs:{"solo":"","multiple":"","accept":"image/png, image/jpeg, image/bmp, video/mp4, video/mov","show-size":"","counter":"","required":""},on:{"change":_vm.onSelectedChallengeChange}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{on:{"click":_vm.updateInfo}},[_vm._v("Añadir información")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }