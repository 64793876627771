
import Mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class MainVideoComponent extends mixins(Mixin) {
  @Prop() video;
  videoElement: HTMLVideoElement | undefined;

  mounted() {
    console.log("mounted MainVideoComponent");
    this.videoElement = document.getElementById("myVideo") as HTMLVideoElement;
    if (this.videoElement != undefined) {
      this.checkforVideo();
      this.videoElement.addEventListener("loadeddata", e => {
        //Video should now be loaded but we can add a second check

        if (this.videoElement!.readyState >= 3) {
          console.log("video loaded");
          //your code goes here
        }
      });
      this.videoElement.oncanplaythrough = () => {
        console.log("oncanplaythrough");
        console.log(this.videoElement!);
        this.videoElement!.muted = true;
        this.videoElement!.play();
      };
    }
  }

  checkforVideo() {
    //Every 500ms, check if the video element has loaded
    const b = setInterval(() => {
      if (this.videoElement!.readyState >= 3) {
        //This block of code is triggered when the video is loaded

        //your code goes here

        //stop checking every half second
        console.log("video loaded");
        clearInterval(b);
      }
    }, 500);
  }
}
