
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { db, storageRef } from "@/main";
import mixin from "@/mixin";
import TitleItem from "@/components/general/TitleItem.vue";
import SubTitleItem from "@/components/general/SubTitleItem.vue";
import axios from "axios";
import { collection, doc, setDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";

@Component({
  components: {
    TitleItem,
    SubTitleItem
  }
})
export default class DialogAddVacancy extends mixins(mixin) {
  newVacancy = {
    job: {
      es: "",
      us: ""
    },
    responsabilities: {
      responsability_1: {
        es: "",
        us: ""
      }
    },
    skills: {
      skill_1: {
        es: "",
        us: ""
      }
    },
    isRemote: false,
    startInmmediately: false,
    creation_date: new Date(),
    year: "2022",
    salary: "",
    description: {
      es: "",
      us: ""
    }
  };

  originalNewVacancy = {
    job: {
      es: "",
      us: ""
    },
    responsabilities: {
      responsability_1: {
        es: "",
        us: ""
      }
    },
    skills: {
      skill_1: {
        es: "",
        us: ""
      }
    },
    is_remote: false,
    start_inmmediately: false,
    creation_date: new Date(),
    year: "2022",
    salary: "",
    description: {
      es: "",
      us: ""
    }
  };
  counterResponsabilities = 1;
  counterSkills = 1;
  years = ["2020", "2021", "2022"];

  dialogAddVacancy = false;
  nameRules = [
    v => (v != undefined ? !!v || "El nombre es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length > 3 || "El nombre debe tener mas de 3 caracteres"
        : ""
  ];
  documentRules = [
    v => (v != undefined ? !!v || "El documento es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length > 5 || "El documento debe tener mas de 5 caracteres"
        : ""
  ];
  departmentRules = [
    v => (v != undefined ? !!v || "El departamento es obligatorio" : "")
  ];
  cityRules = [v => (v != undefined ? !!v || "La ciudad es obligatoria" : "")];
  timeRules = [v => (v != undefined ? !!v || "La hora es obligatoria" : "")];
  addressRules = [
    v => (v != undefined ? !!v || "La dirección es obligatoria" : ""),
    v =>
      v != undefined
        ? v.length > 5 || "La dirección debe tener mas de 5 caracteres"
        : ""
  ];
  rulesImage = [
    v => (v != undefined ? !!v || "La imagen es obligatorio" : ""),
    v =>
      !v || v.size < 2000000 || "El tamaño de la imagen debe ser menor de 2 MB!"
  ];
  emailRules = [
    v => !!v || "El E-mail es obligatorio",
    v => /.+@.+\..+/.test(v) || "El E-mail debe ser válido"
  ];
  phoneRules = [
    v => (v != undefined ? !!v || "El teléfono es obligatorio" : ""),
    v =>
      v != undefined ? v.length === 7 || "El teléfono debe tener 7 números" : ""
  ];
  obligatoryRules = [
    v => (v != undefined ? !!v || "El campo es obligatorio" : "")
  ];
  cellphoneRules = [
    v => (v != undefined ? !!v || "El celular es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length === 10 || "El celular debe tener 10 números"
        : ""
  ];

  openDialogAddVacancy() {
    this.dialogAddVacancy = true;
  }

  @Watch("newVacancy.job")
  onNewVacancyJob(val) {
    console.log(val);
  }

  addResponsability() {
    Vue.set(
      this.newVacancy.responsabilities,
      "responsability_" + (this.counterResponsabilities + 1),
      {
        es: "",
        us: ""
      }
    );
    this.counterResponsabilities > 0 ? (this.counterResponsabilities += 1) : 1;
  }
  removeResponsability() {
    this.counterResponsabilities > 0 ? (this.counterResponsabilities -= 1) : 1;
    console.log(this.counterResponsabilities);
    Vue.delete(
      this.newVacancy.responsabilities,
      "responsability_" + this.counterResponsabilities
    );
  }
  addSkills() {
    Vue.set(this.newVacancy.skills, "skills_" + (this.counterSkills + 1), {
      es: "",
      us: ""
    });
    this.counterSkills > 0 ? (this.counterSkills += 1) : 1;
  }
  removeSkills() {
    this.counterSkills > 0 ? (this.counterSkills -= 1) : 1;
    console.log(this.counterSkills);
    Vue.delete(this.newVacancy.skills, "skills_" + this.counterSkills);
  }

  validateFields() {
    if (
      this.newVacancy.job.es !== "" &&
      this.newVacancy.description.es !== "" &&
      this.newVacancy.description.us !== ""
    ) {
      this.createVacancy();
    } else {
      console.log(this.newVacancy.description.es);
      console.log(this.newVacancy.description.us);
      this.showNotification(
        "info",
        "Faltan Campos",
        "Completa todos los campos",
        3000
      );
    }
  }

  async createVacancy() {
    //toDo
    try {
      const newVacancyRef = doc(
        collection(db, "website", "requests", "vacancies")
      );
      console.log(newVacancyRef.id);

      this.showNotification(
        "loading",
        "Subiendo multimedia de fondo",
        "Un momento por favor..."
      );

      this.showNotification("loading", "Creando Vacante");
      this.newVacancy["creation_date"] = new Date();

      await setDoc(newVacancyRef, this.newVacancy)
        .then(async () => {
          this.showNotification(
            "success",
            "Vacante agregada",
            "La vacante fue agregada con éxito."
          );
          this.dialogAddVacancy = false;
          //this.selectedImage = new File([""], "");
        })
        .catch(error => {
          console.log(error);
          this.showNotification("fail", "Error", "Error al crear la vacante");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      this.showNotification("fail", "Error", "Error al crear la vacante");
    }
  }
}
