
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ButtonMenu from "./ButtonMenu.vue";
import DialogAddProject from "@/components/projects/admin/dialogAddProject.vue";
import DialogAddVacancy from "@/components/projects/admin/dialogAddVacancy.vue";
@Component({
  components: {
    ButtonMenu,
    DialogAddProject,
    DialogAddVacancy,
  },
})
export default class Header extends mixins(mixin) {
  showFullScreenMenu = false;
  lang_selected = "ES";
  isMenuOpened = false;
  mounted() {
    /*
    console.log(document.getElementById("menuToggle"));
    console.log(document.getElementById("menuToggle")!["checked"]);
    */
    console.log(this.$route.name);
  }

  changeLang() {
    console.log("changeLang");
    this.$store.state.lang = this.$store.state.lang == "es" ? "us" : "es";
  }

  @Watch("lang_selected")
  onLangSelected(val) {
    console.log("lang_selected: " + val);
    this.$store.state.lang = val.toLowerCase();
  }

  openMenu() {
    this.isMenuOpened = !this.isMenuOpened;
    console.log(this.isMenuOpened);
    if (this.isMenuOpened) {
      this.$store.state.menuDark = false;
    } else {
      if (this.$store.state.isOverlap) {
        this.$store.state.menuDark = true;
      } else {
        this.$store.state.menuDark = false;
      }
    }
    console.log("isMenuOpened: " + this.isMenuOpened);
  }

  goRoute(route) {
    this.$store.state.showFooter = true;
    console.log(this.$store.state.showFooter);
    this.$router
      .push({
        name: route,
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
