
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import Branding from "@/components/project_details/Branding.vue";
import Challenge from "@/components/project_details/Challenge.vue";
import Marketing from "@/components/project_details/Marketing.vue";
import NextProject from "@/components/project_details/NextProject.vue";
import ProjectImage from "@/components/projects/ProjectImage.vue";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where
} from "firebase/firestore";
import { db } from "@/main";
import { Watch } from "vue-property-decorator";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
    Branding,
    Challenge,
    Marketing,
    NextProject,
    ProjectImage
  }
})
export default class ServicetDetail extends mixins(mixin) {
  project_name = "";
  service_slug = "";
  service_info: any = null;
  projects: any = [];

  async mounted() {
    this.$store.state.menuDark = false;
    this.$store.state.isLoaded = false;
    this.service_slug = this.$route.params.slug;

    const docData = doc(db, "website", "data");
    const result = await getDoc(docData);
    this.service_info = result.data()!.services[this.service_slug];
    this.$store.state.isLoaded = true;
    this.getProjectsByService(this.service_slug);
  }

  @Watch("$store.state.allProjects")
  onProjectsChange(val) {
    this.getProjectsByService(this.service_slug);
  }

  getProjectsByService(service) {
    console.log("getProjectsByService");
    console.log(service);
    console.log(this.$store.state.allProjects);
    if (service === "all") {
      this.projects = this.$store.state.allProjects;
    } else {
      this.projects = this.$store.state.allProjects.filter(project => {
        return project.services.includes(service);
      });
    }
    console.log(this.projects);
  }
}
