var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{class:{
    'ma-0 pa-0 navigation-item-no-border': _vm.$vuetify.breakpoint.mdAndUp,
    'navigation-item-no-border': _vm.$vuetify.breakpoint.smAndDown,
  },attrs:{"cols":"auto"}},[_c('a',{class:{
    'text-capitalize fullscreen-text px-6 navigation-link': _vm.$vuetify.breakpoint.mdAndUp,
    'text-capitalize fullscreen-text navigation-link': _vm.$vuetify.breakpoint.smAndDown,
  },attrs:{"href":"#","text":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.goRoute()}}},[_c('span',{staticClass:"item-button mr-2",attrs:{"data-text":_vm.name}},[_vm._v(_vm._s(_vm.name))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }