
import mixin from "@/mixin";
import Component, { mixins } from "vue-class-component";
import Branding from "@/components/project_details/Branding.vue";
import Challenge from "@/components/project_details/Challenge.vue";
import Marketing from "@/components/project_details/Marketing.vue";
import Apps from "@/components/project_details/Apps.vue";
import Webs from "@/components/project_details/Webs.vue";
import NextProject from "@/components/project_details/NextProject.vue";
import MainVideoComponent from "@/components/services/mainVideoComponent.vue";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/main";
import { Watch } from "vue-property-decorator";
import * as THREE from "three";
@Component({
  components: {
    Branding,
    Challenge,
    Marketing,
    Apps,
    Webs,
    NextProject,
    MainVideoComponent,
  },
})
export default class ProjectDetail extends mixins(mixin) {
  project_name = "";
  project_slug = "";
  project_info: any = null;
  nextProjectClicked = false;
  next_project_slug = null;
  overlapProjectDetails = false;
  maxImageWidth = "600px";
  activated() {
    if (this.project_info !== null) {
      this.$store.state.isLoaded = true;
    }
  }

  async mounted() {
    this.$store.state.isLoaded = false;
    console.log(this.$route.params.slug);
    console.log(this.project_info);
    this.project_slug = this.$route.params.slug;

    const subColRef = collection(db, "website", "data", "projects");
    const q = query(
      subColRef,
      where("slug", "==", this.project_slug),
      limit(1)
    );
    const result = await getDocs(q);
    if (result.docs.length > 0) {
      this.project_info = result.docs[0].data();
      this.project_info["id"] = result.docs[0].id;
    }
    this.$store.state.isLoaded = true;

    this.getImageWidth();
    this.getFontSize();

    window.addEventListener("resize", (event) => {
      this.getImageWidth();
      this.getFontSize();
    });
  }
  beforeUnmount() {
    console.log("beforeUnmount");
  }

  unmounted() {
    console.log("unmounted");
  }

  updated() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.$store.state.menuDark = false;
    }
    console.log("Updated ProjectDetails");
    if (this.project_info !== null) {
      this.$store.state.isLoaded = true;
    }
    const target = document.querySelectorAll(".animation");
    /*for (let i = 0; i < target.length; i++) {
      if (target[i] !== undefined) {
        if (this.$store.state.observer !== null) {
          this.$store.state.observer.observe(target[i]);
        }
      }
    }*/
    if (this.$store.state.sortedProjects.length > 0) {
      this.startListenHover();
    }
  }

  startListenHover() {
    if (this.$store.state.cursor !== null) {
      let index = this.$store.state.sortedProjects.findIndex(
        (project) => project.slug === this.project_slug
      );

      if (index == -1) {
        index = 0;
      } else {
        index = index + 1;
      }
      const nextProject = this.$store.state.sortedProjects[index];
      this.next_project_slug = this.$store.state.sortedProjects[index].slug;
      if (nextProject === undefined && nextProject === null) {
        this.next_project_slug = this.$store.state.allProjects[0].slug;
      }
      console.log("next: " + this.next_project_slug);
      setTimeout(() => {
        const classProject = document.querySelector(
          "." + this.next_project_slug
        );
        classProject!.addEventListener("mouseenter", () => {
          if (!this.nextProjectClicked) {
            //this.$store.state.cursor.hide();
            const list = classProject!.classList;
            const slug = list[classProject!.classList.length - 1];
            const projectBySlug = this.$store.state.allProjects.find(
              (projectToFind) => projectToFind.slug === slug
            );
            if (projectBySlug != undefined) {
              this.$store.state.cursor.setImg(projectBySlug.main_image);
            }
          }
        });

        classProject!.addEventListener("mouseleave", () => {
          this.$store.state.cursor.removeImg();
          this.$store.state.cursor.show();
        });

        classProject!.addEventListener("click", () => {
          this.$store.state.cursor.removeImg();
          this.nextProjectClicked = true;
        });
      }, 500);
    }
  }

  onSelectedVideoChange(val) {
    this.$store.state.selectedMedia = val;
    const refToUpdate = doc(
      db,
      "website",
      "data",
      "projects",
      this.project_info["id"]
    );
    this.updateMedia(
      val,
      this.project_info["slug"],
      refToUpdate,
      "detail_video"
    );
  }

  onSelectedDetailImageChange(val) {
    this.$store.state.selectedMedia = val;
    const refToUpdate = doc(
      db,
      "website",
      "data",
      "projects",
      this.project_info["id"]
    );
    this.updateMedia(
      val,
      this.project_info["slug"],
      refToUpdate,
      "detail_image_comp"
    );
  }

  onSelectedMainImageChange(val) {
    this.$store.state.selectedMedia = val;
    const refToUpdate = doc(
      db,
      "website",
      "data",
      "projects",
      this.project_info["id"]
    );
    this.updateMedia(
      val,
      this.project_info["slug"],
      refToUpdate,
      "detail_image"
    );
  }

  @Watch("$store.state.projectsLoaded")
  onProjectsLoaded(val) {
    if (val) {
      if (this.$store.state.sortedProjects.length === 0) {
        const selectedProjectIndex = this.$store.state.allProjects.findIndex(
          (project) => project.slug === this.project_slug
        );
        if (selectedProjectIndex !== -1) {
          this.$store.state.sortedProjects = [...this.$store.state.allProjects];
          this.$store.state.sortedProjects.splice(selectedProjectIndex, 1);
          this.$store.state.sortedProjects.unshift(this.project_info);
        }
      }
      this.startListenHover();
    }
  }

  getFontSize() {
    const textLength = this.project_info.name.split("").length;
    const width = window.innerWidth;
    if (textLength > 0 && textLength <= 5) {
      if (width > 2600) return "58em";

      if (width > 1500 && width < 2600) return "48em";

      if (width > 700 && width < 1500) return "28em";

      if (width < 700) return "10em";
    } else if (textLength > 6 && textLength <= 9) {
      if (width > 2600) return "47em";

      if (width > 1500 && width < 2600) return "29em";

      if (width > 700 && width < 1500) return "15em";

      if (width < 700) return "6em";
    } else {
      if (width > 2600) return "41em";

      if (width > 1500 && width < 2600) return "26em";

      if (width > 700 && width < 1500) return "12em";

      if (width < 700) return "3em";
    }
  }

  getImageWidth() {
    const width = window.innerWidth;
    if (width > 3000) {
      this.maxImageWidth = "2500px";
    }

    if (width > 2600 && width < 3000) this.maxImageWidth = "1600px";

    if (width > 1900 && width < 2600) this.maxImageWidth = "820px";

    if (width > 1600 && width < 1900) this.maxImageWidth = "800px";

    if (width > 1400 && width < 1600) this.maxImageWidth = "800px";

    if (width > 1100 && width < 1400) this.maxImageWidth = "650px"; //650 windows normal

    if (width > 950 && width < 1100) this.maxImageWidth = "700px";

    if (width > 650 && width < 950) this.maxImageWidth = "700px";

    if (width < 650) this.maxImageWidth = "610px";
  }

  @Watch("maxImageWidth")
  onMaxImageWidth(val) {
    console.log("maxImageWidth change: " + val);
  }
}
