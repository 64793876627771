var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"ma-0 pa-0",style:({
    'background-image': 'url(' + _vm.image + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-origin': 'content-box',
    height: _vm.$vuetify.breakpoint.smAndDown ? '400px' : '85vh',
    width: '100%'
  }),attrs:{"cols":"12","md":"12"}})
}
var staticRenderFns = []

export { render, staticRenderFns }