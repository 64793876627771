
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import ProjectText from "@/components/projects/ProjectText.vue";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/general/Loader.vue";
import MouseFollower from "mouse-follower";

@Component({
  components: {
    ProjectText,
    Loader
  }
})
export default class Projects extends mixins(mixin) {
  selectedService = "app";
  all = {
    us: "All",
    es: "Todos"
  };
  mounted() {
    this.$store.state.projectClicked = false;
    if (this.$store.state.projectsLoaded) {
      this.addHoverListeners();
    }
  }

  @Watch("$store.state.projectsLoaded")
  onProjectsLoaded(val) {
    console.log("watching projectsLoaded: " + val);
    if (val) {
      this.getProjectsByService("all");
      setTimeout(() => {
        (document as any).getElementById("all").checked = true;
      }, 500);
    }
  }

  @Watch("$store.state.projectsByServiceLoaded")
  onProjectsByServicesLoaded(val) {
    console.log("watching projectsByServiceLoaded: " + val);
    if (val) {
      this.addHoverListeners();
    }
  }

  addHoverListeners() {
    if (this.$store.state.projectsByService.length == 0) {
      this.getProjectsByService("all");
    }
    if (this.$store.state.cursor !== null) {
      setTimeout(() => {
        console.log("Projects loaded");
        console.log(this.$store.state.projectsByService.length);
        for (
          let index = 0;
          index < this.$store.state.projectsByService.length;
          index++
        ) {
          const project = this.$store.state.projectsByService[index];
          const classProject = document.querySelector("." + project.slug);
          classProject!.addEventListener("mouseenter", () => {
            if (!this.$store.state.projectClicked) {
              console.log("mouseenter");
              //this.$store.state.cursor.hide();
              //this.$store.state.cursor.setImg("/img/loading_animation.gif");
              //this.$store.state.cursor.setText("Hello!");

              const list = classProject!.classList;
              const slug = list[classProject!.classList.length - 1];
              console.log(slug);
              const projectBySlug = this.$store.state.projectsByService.find(
                projectToFind => projectToFind.slug === slug
              );
              if (projectBySlug != undefined) {
                //this.$store.state.cursor.setText("lalala");
                this.$store.state.cursor.setImg(projectBySlug.main_image);
              }
            }
          });

          classProject!.addEventListener("mouseleave", () => {
            //this.$store.state.cursor.removeText();
            this.$store.state.cursor.removeImg();
            this.$store.state.cursor.show();
          });

          classProject!.addEventListener("click", () => {
            //this.$store.state.cursor.removeText();
            this.$store.state.cursor.removeImg();
            this.$store.state.projectClicked = true;
          });
        }
      }, 100);
    }
  }

  @Watch("selectedService")
  onSelectedService(val) {
    console.log("selectedService change");
    console.log(val);
    this.getProjectsByService(val);
    if (val !== "all") {
      (document as any).getElementById("all").checked = false;
    }
  }
}
