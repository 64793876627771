
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { db, storageRef } from "@/main";
import mixin from "@/mixin";
import TitleItem from "@/components/general/TitleItem.vue";
import SubTitleItem from "@/components/general/SubTitleItem.vue";
import axios from "axios";
import { collection, doc, setDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";

@Component({
  components: {
    TitleItem,
    SubTitleItem
  }
})
export default class DialogAddProject extends mixins(mixin) {
  newProject = {
    name: "",
    slug: "",
    priority: 1,
    creation_date: new Date(),
    year: "2022",
    description: {
      es: "",
      us: ""
    },
    main_image: "",
    main_video: "",
    detail_image: "",
    detail_video: "",
    detail_image_comp: "",
    color: "",
    challenge: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    apps: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    webs: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    branding: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    marketing: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    design: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    services: ["apps"]
  };

  originalNewProject = {
    name: "",
    slug: "",
    priority: 1,
    creation_date: new Date(),
    year: "2022",
    description: {
      es: "",
      us: ""
    },
    main_image: "",
    main_video: "",
    detail_image: "",
    detail_video: "",
    detail_image_comp: "",
    color: "",
    challenge: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    apps: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    webs: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    branding: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    marketing: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    design: {
      art: {},
      text: {
        es: "",
        us: ""
      }
    },
    services: ["apps"]
  };

  years = ["2020", "2021", "2022"];

  dialogAddProject = false;
  nameRules = [
    v => (v != undefined ? !!v || "El nombre es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length > 3 || "El nombre debe tener mas de 3 caracteres"
        : ""
  ];
  documentRules = [
    v => (v != undefined ? !!v || "El documento es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length > 5 || "El documento debe tener mas de 5 caracteres"
        : ""
  ];
  departmentRules = [
    v => (v != undefined ? !!v || "El departamento es obligatorio" : "")
  ];
  cityRules = [v => (v != undefined ? !!v || "La ciudad es obligatoria" : "")];
  timeRules = [v => (v != undefined ? !!v || "La hora es obligatoria" : "")];
  addressRules = [
    v => (v != undefined ? !!v || "La dirección es obligatoria" : ""),
    v =>
      v != undefined
        ? v.length > 5 || "La dirección debe tener mas de 5 caracteres"
        : ""
  ];
  rulesImage = [
    v => (v != undefined ? !!v || "La imagen es obligatorio" : ""),
    v =>
      !v || v.size < 2000000 || "El tamaño de la imagen debe ser menor de 2 MB!"
  ];
  emailRules = [
    v => !!v || "El E-mail es obligatorio",
    v => /.+@.+\..+/.test(v) || "El E-mail debe ser válido"
  ];
  phoneRules = [
    v => (v != undefined ? !!v || "El teléfono es obligatorio" : ""),
    v =>
      v != undefined ? v.length === 7 || "El teléfono debe tener 7 números" : ""
  ];
  obligatoryRules = [
    v => (v != undefined ? !!v || "El campo es obligatorio" : "")
  ];
  cellphoneRules = [
    v => (v != undefined ? !!v || "El celular es obligatorio" : ""),
    v =>
      v != undefined
        ? v.length === 10 || "El celular debe tener 10 números"
        : ""
  ];
  selectedMainImage: File = new File([""], "");
  selectedMainVideo: File = new File([""], "");

  selectedDetailImageComp: File = new File([""], "");
  selectedDetailImage: File = new File([""], "");
  selectedDetailVideo: File = new File([""], "");
  selectedChallengeMedia: File = new File([""], "");
  selectedAppsMedia: File = new File([""], "");
  selectedWebsMedia: File = new File([""], "");
  selectedBrandingMedia: File = new File([""], "");
  selectedMarketingMedia: File = new File([""], "");
  selectedDesignMedia: File = new File([""], "");

  openDialogAddProject() {
    this.dialogAddProject = true;
  }

  @Watch("newProject.name")
  onNewProjectName(val) {
    console.log(val);
    this.newProject.slug = val
      .split(" ")
      .join("_")
      .toLowerCase();
    console.log(this.newProject.slug);
  }

  @Watch("newProject.services")
  onNewProjectServices(val) {
    console.log(val);
  }

  onSelectedMainImageChange(val) {
    console.log(val);
    this.selectedMainImage = val;
  }

  onSelectedMainVideoChange(val) {
    console.log(val);
    this.selectedMainVideo = val;
  }

  onSelectedDetailImageChange(val) {
    console.log(val);
    this.selectedDetailImage = val;
  }

  onSelectedDetailImageCompChange(val) {
    console.log(val);
    this.selectedDetailImageComp = val;
  }

  onSelectedDetailVideoChange(val) {
    console.log(val);
    this.selectedDetailVideo = val;
  }

  onSelectedChallengeChange(val) {
    console.log(val);
    console.log(val[0]);
    this.selectedChallengeMedia = val;
  }

  onSelectedAppsMediaChange(val) {
    console.log(val);
    console.log(val[0]);
    this.selectedAppsMedia = val;
  }
  onSelectedWebsMediaChange(val) {
    console.log(val);
    this.selectedWebsMedia = val;
  }
  onSelectedBrandingMediaChange(val) {
    console.log(val);
    this.selectedBrandingMedia = val;
  }
  onSelectedMarketingMediaChange(val) {
    console.log(val);
    this.selectedMarketingMedia = val;
  }
  onSelectedDesignMediaChange(val) {
    console.log(val);
    this.selectedDesignMedia = val;
  }

  validateFields() {
    if (
      this.newProject.name !== "" &&
      this.newProject.slug !== "" &&
      this.newProject.description.es !== "" &&
      this.newProject.description.us !== "" &&
      (this.selectedMainImage !== undefined ||
        this.selectedMainVideo !== undefined) &&
      this.newProject.color !== "" &&
      this.selectedDetailImageComp !== undefined
    ) {
      this.createProject();
    } else {
      console.log(this.newProject.name);
      console.log(this.newProject.slug);
      console.log(this.newProject.description.es);
      console.log(this.newProject.description.us);
      console.log(this.selectedMainImage);
      console.log(this.selectedMainVideo);
      console.log(this.newProject.color);
      console.log(this.selectedDetailImageComp);
      this.showNotification(
        "info",
        "Faltan Campos",
        "Completa todos los campos",
        3000
      );
    }
  }

  async createProject() {
    //toDo
    try {
      const newProjectRef = doc(collection(db, "website", "data", "projects"));
      console.log(newProjectRef.id);

      this.showNotification(
        "loading",
        "Subiendo multimedia de fondo",
        "Un momento por favor..."
      );

      const storage = getStorage();
      if (
        this.selectedMainImage.name != null &&
        this.selectedMainImage.name != ""
      ) {
        debugger;
        const storageRef = ref(
          storage,
          "/projects/" +
            this.newProject.slug +
            "/" +
            this.selectedMainImage.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedMainImage
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.newProject.main_image = downloadURL;
        console.log("selectedMainImage success upload ");
        console.log(downloadURL);
      }

      if (
        this.selectedMainVideo.name != null &&
        this.selectedMainVideo.name != ""
      ) {
        debugger;
        const storageRef = ref(
          storage,
          "/projects/" +
            this.newProject.slug +
            "/" +
            this.selectedMainVideo.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedMainVideo
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.newProject.main_video = downloadURL;
        console.log("selectedMainVideo success upload ");
        console.log(downloadURL);
      }

      if (
        this.selectedDetailImage.name != null &&
        this.selectedDetailImage.name != ""
      ) {
        debugger;
        const storageRef = ref(
          storage,
          "/projects/" +
            this.newProject.slug +
            "/" +
            this.selectedDetailImage.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedDetailImage
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.newProject.detail_image = downloadURL;
        console.log("selectedDetailImage success upload ");
        console.log(downloadURL);
      }

      if (
        this.selectedDetailVideo.name != null &&
        this.selectedDetailVideo.name != ""
      ) {
        debugger;
        const storageRef = ref(
          storage,
          "/projects/" +
            this.newProject.slug +
            "/" +
            this.selectedDetailVideo.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedDetailVideo
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.newProject.detail_video = downloadURL;
        console.log("selectedDetailVideo success upload ");
        console.log(downloadURL);
      }

      if (
        this.selectedDetailImageComp != null &&
        this.selectedDetailImageComp != undefined
      ) {
        debugger;
        const storageRef = ref(
          storage,
          "/projects/" +
            this.newProject.slug +
            "/" +
            this.selectedDetailImageComp.name
        );
        const uploadTask = await uploadBytesResumable(
          storageRef,
          this.selectedDetailImageComp
        );
        const downloadURL = await getDownloadURL(uploadTask.ref);
        this.newProject.detail_image_comp = downloadURL;
        console.log("selectedDetailImageComp success upload ");
        console.log(downloadURL);
      }

      //MEDIA SERVICES
      if (
        this.selectedChallengeMedia != null &&
        this.selectedChallengeMedia != undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedChallengeMedia as any).length;
          index++
        ) {
          debugger;
          const media = this.selectedChallengeMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/challenge/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.challenge.art[index + 1] = downloadURL;
        }
      }

      if (
        this.selectedAppsMedia != null &&
        this.selectedAppsMedia != undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedAppsMedia as any).length;
          index++
        ) {
          debugger;
          const media = this.selectedAppsMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/apps/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.apps.art[index + 1] = downloadURL;
        }
      }

      if (
        this.selectedWebsMedia != null &&
        this.selectedWebsMedia != undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedWebsMedia as any).length;
          index++
        ) {
          const media = this.selectedWebsMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/webs/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.webs.art[index + 1] = downloadURL;
        }
      }

      if (
        this.selectedBrandingMedia != null &&
        this.selectedBrandingMedia !== undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedBrandingMedia as any).length;
          index++
        ) {
          const media = this.selectedBrandingMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/branding/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.branding.art[index + 1] = downloadURL;
        }
      }

      if (
        this.selectedMarketingMedia != null &&
        this.selectedMarketingMedia != undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedMarketingMedia as any).length;
          index++
        ) {
          const media = this.selectedMarketingMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/marketing/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.marketing.art[index + 1] = downloadURL;
        }
      }

      if (
        this.selectedDesignMedia != null &&
        this.selectedDesignMedia != undefined
      ) {
        for (
          let index = 0;
          index < (this.selectedDesignMedia as any).length;
          index++
        ) {
          const media = this.selectedDesignMedia[index];
          const storageRef = ref(
            storage,
            "/projects/" + this.newProject.slug + "/design/" + media.name
          );
          const uploadTask = await uploadBytesResumable(storageRef, media);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          this.newProject.design.art[index + 1] = downloadURL;
        }
      }

      this.showNotification("loading", "Creando Proyecto");
      this.newProject["creation_date"] = new Date();

      await setDoc(newProjectRef, this.newProject)
        .then(async () => {
          this.showNotification(
            "success",
            "Proyecto agregado",
            "El Proyecto fue agregado con éxito."
          );
          this.dialogAddProject = false;
          //this.selectedImage = new File([""], "");
        })
        .catch(error => {
          console.log(error);
          this.showNotification("fail", "Error", "Error al crear el proyecto");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      this.showNotification("fail", "Error", "Error al crear el proyecto");
    }
  }
}
