
import Mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
declare const gtag: any
@Component
export default class ButtonPlans extends mixins(Mixin) {
  @Prop() backgroundColor;
  @Prop() backgroundColorRGB;
  @Prop() textColor;
  @Prop() text;
  @Prop() ctaWhatsapp;
  @Prop() trackGoogleAds;
  goToPlans() {
    const target = document.querySelector("#section-9-plans");
    this.$store.state.scrollbar.scrollTo(target);
  }

  goToWhatsApp() {
    if (!this.trackGoogleAds) {
      this.whatsapp();
    } else {
      let url = "";
      if (this.$store.state.lang === "es") {
        url =
          "https://wa.me/12016925201?text=%C2%A1Hola%21+quisiera+mas+informaci%C3%B3n";
      } else {
        url = "https://wa.me/12016925201?text=%C2%A1Hi%21+i+want+more+info";
      }

      this.gtag_report_conversion(url);
    }
  }

  gtag_report_conversion(url) {
    console.log("gtag_report_conversion");
    console.log(url);
    const callback = function () {
      if (typeof url != "undefined") {
        window.open(url, "_blank");
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-11466242031/9XGWCJ7CzokZEO_vw9sq",
      event_callback: callback,
    });
    return false;
  }

  onHover() {
    console.log("onHover");
    document.documentElement.style.setProperty(
      "--button-color",
      "rgb(" + this.backgroundColorRGB + ", 0.4)"
    );
  }
}
