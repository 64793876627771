var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.service.display_style === 'title_outside_left')?_c('v-col',{staticClass:"mx-0 px-0"},[_c('section',[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"md":"12"}},[_c('h1',{staticClass:"main-title-section left"},[_vm._v(" "+_vm._s(_vm.service.name[_vm.$store.state.lang])+" ")])])],1),_c('section',{class:{
      'my-16 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
      'my-6 pa-0': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"mx-0 px-0",attrs:{"md":"6","sm":"12","cols":"12"}},[_c('MainMediaComponent',{attrs:{"image":_vm.service.image,"video":_vm.service.video}})],1),_c('v-col',{class:{
          'pl-10': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-0': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"md":"6","sm":"12","cols":"12"}},[_c('MainContentComponent',{attrs:{"name":_vm.service.name,"description":_vm.service.description,"serviceKey":_vm.serviceKey}})],1)],1)],1)]):(_vm.service.display_style === 'title_outside_right')?_c('v-col',[_c('section',[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"md":"12"}},[_c('h1',{staticClass:"main-title-section right"},[_vm._v(" "+_vm._s(_vm.service.name[_vm.$store.state.lang])+" ")])])],1),_c('section',{class:{
      'my-16 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
      'my-6 pa-0': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{class:{
          'pr-10': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-0': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"md":"6","sm":"12","cols":"12","order":_vm.$vuetify.breakpoint.mdAndUp ? 1 : 2}},[_c('MainContentComponent',{attrs:{"name":_vm.service.name,"description":_vm.service.description,"serviceKey":_vm.serviceKey}})],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('MainMediaComponent',{attrs:{"image":_vm.service.image,"video":_vm.service.video}})],1)],1)],1)]):(_vm.service.display_style === 'title_inside_right')?_c('v-col',[_c('section',{class:{
      'my-16 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
      'my-6 pa-0': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('MainMediaComponent',{attrs:{"image":_vm.service.image,"video":_vm.service.video}})],1),_c('v-col',{class:{
          'pl-10': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-0': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"md":"6","sm":"12","cols":"12"}},[_c('h1',{staticClass:"main-title-section left"},[_vm._v(" "+_vm._s(_vm.service.name[_vm.$store.state.lang])+" ")]),_c('MainContentComponent',{attrs:{"name":_vm.service.name,"description":_vm.service.description,"serviceKey":_vm.serviceKey}})],1)],1)],1)]):(_vm.service.display_style === 'fullscreen')?_c('v-col',[_c('section',[_c('v-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"main-title-section center"},[_vm._v(" "+_vm._s(_vm.service.name[_vm.$store.state.lang])+" ")])])],1),_c('section',{class:{
      'my-16 pa-0': _vm.$vuetify.breakpoint.mdAndUp,
      'my-6 pa-0': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('MainMediaComponent',{attrs:{"image":_vm.service.image,"video":_vm.service.video}}),_c('MainContentComponent',{attrs:{"name":_vm.service.name,"description":_vm.service.description,"serviceKey":_vm.serviceKey}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }