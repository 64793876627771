
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class ImageOrVideoAsset extends mixins(mixin) {
  @Prop() asset;
  @Prop() isFullScreen;

  /*mounted() {
    console.log("ImageAsset mounted");
    console.log(this.currentID);
  }*/
}
