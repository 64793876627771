
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import ProjectText from "@/components/projects/ProjectText.vue";
import ServiceComponent from "@/components/services/serviceComponent.vue";
import MainVideoComponent from "@/components/services/mainVideoComponent.vue";
@Component({
  components: {
    ProjectText,
    ServiceComponent,
    MainVideoComponent
  }
})
export default class Services extends mixins(mixin) {
  selectedService = "app";
  all = {
    us: "All",
    es: "Todos"
  };
  projects: any = [];
  mounted() {
    this.projects = this.$store.state.allProjects;
    this.$store.state.menuDark = false;
  }
}
