import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isEditing: false,
    //Debe estar en falso al subir
    localVisible: false,
    isOverlap: false,
    showFooter: true,
    maintenance: {},
    isMenuOverlap: false,
    projectClicked: false,
    colorHeader: "transparent",
    contactBtnColor: "#141D51",
    contactBtnIconColor: "#fff",
    cursor: null as any,
    scrollbar: null as any,
    observer: null as any,
    isLoaded: false,
    scrollValue: 0,
    version: "",
    enableSound: false,
    scene: null as any,
    textureLoader: null as any,
    geometry: null as any,
    renderer: null as any,
    clock: null as any,
    raycaster: null as any,
    codes: {},
    sizes: {},
    user: {
      uid: "",
      type: "",
      subType: "",
      client_data: {} as any,
      data: {} as any
    },
    drawer: null,
    employees: {},
    featuredProjects: [] as any[],
    normalProjects: [] as any[],
    allProjects: [] as any[],
    sortedProjects: [] as any[],
    projectsByService: [] as any[],
    projectsLoaded: false,
    projectsByServiceLoaded: false,
    lang: "es",
    langs: ["ES", "US"],
    menuDark: true,
    showSCSDialog: false,
    texts: {
      others: {
        main: {
          /*main_1: {
            es: "BRANDS, STRATEGIES AND SOFTWARE TO INSPIRE PEOPLE",
            us: "WE ARE"
          },*/
          main_1: {
            es: "APPS, WEBS, BRANDING & MARKETING",
            us: "WE ARE"
          },
          main_2: {
            es: "EXPERTOS",
            us: "EXPERTS"
          },
          main_3: {
            es: "EN",
            us: "IN"
          }
        },
        second: {
          title: {
            es: "ESTO SOMOS",
            us: "THIS WE ARE"
          },
          description: {
            es:
              "Somos unos locos por crear conceptos que se adaptan a cada forma de comunicar una idea o servicio.",
            us:
              "We are crazy about creating concepts that adapt to each way of communicating an idea or service."
          }
        },
        featuredProjects: {
          one: {
            es: "Proyectos",
            us: "Featured"
          },
          two: {
            es: "destacados",
            us: "projects"
          }
        },
        normalProjects: {
          one: {
            es: "Otros",
            us: "Other"
          },
          two: {
            es: "proyectos",
            us: "projects"
          }
        },
        see: {
          es: "Ver",
          us: "See"
        }
      },
      menu: {
        home: {
          es: "Inicio",
          us: "Home"
        },
        projects: {
          es: "Proyectos",
          us: "Projects"
        },
        services: {
          es: "Servicios",
          us: "Services"
        },
        about: {
          es: "Nosotros",
          us: "About"
        },
        others: {
          es: "No vieron la luz",
          us: "Others"
        },
        contact: {
          es: "Hablemos",
          us: "Let's Talk"
        }
      },
      challenge: {
        us: "Our Challenge",
        es: "Nuestro Reto"
      },
      marketing: {
        us: "Marketing",
        es: "Marketing"
      },
      contact: {
        page: {
          title: {
            us: "Hi! Tell us more about your idea 💪🏻",
            es: "¡Hola! Cuéntanos acerca de tu idea 💪🏻"
          },
          interest_text: {
            us: "i'm looking for...",
            es: "Estoy bucando ..."
          },
          interest_items: {
            website_scratch: {
              us: "Website",
              es: "Sitio web desde 0"
            },
            web_platform: {
              us: "Back Office",
              es: "Back Office - Plataforma Administrativa"
            },
            app_scratch: {
              us: "App",
              es: "App desde 0"
            },
            marketing: {
              us: "Marketing",
              es: "Marketing Digital"
            },
            branding: {
              us: "Branding",
              es: "Branding"
            },
            design: {
              us: "UX/UI/General design ",
              es: "UI/UX/ Diseño general"
            }
          },
          budget: {
            text: {
              us: "Project Budget",
              es: "Presupuesto para tu proyecto"
            },
            currency: {
              us: "Currency",
              es: "Moneda"
            },
            items: {
              USD: ["<10k", "10-20k", "30-40k", "40-50k", "50-100k", "100k>"],
              EUR: ["<10k", "10-20k", "30-40k", "40-50k", "50-100k", "100k>"],
              COP: ["<10M", "10-20M", "30-40M", "40-50M", "50-100M", "100M>"]
            }
          },
          send: {
            us: "Send request",
            es: "Enviar solicitud"
          },
          messages: {
            success: {
              us:
                "Thank you for contacting us. We will contact you as soon as possible 💪🏻",
              es:
                "Gracias por contactarnos. Nos comunicaremos lo antes posible contigo 💪🏻"
            },
            fail: {
              us: "Oops, an error occurred. Try again",
              es: "Oops, Ocurrió un error. Intenta nuevamente"
            }
          }
        },
        footer: {
          title: {
            us: "Have an idea?",
            es: "¿Tienes una idea?"
          },
          link: {
            us: "Let's talk",
            es: "Hablemos"
          }
        }
      },
      contact_code: {
        page: {
          title: {
            us: "Hi! Tell us more about your idea 💪🏻",
            es: "¡Hola! dejános tus datos y nos contactaremos contigo 💪🏻"
          },
          send: {
            us: "Send request",
            es: "Enviar solicitud"
          },
          messages: {
            success: {
              us:
                "Thank you for contacting us. We will contact you as soon as possible 💪🏻",
              es:
                "Gracias por contactarnos. Nos comunicaremos lo antes posible contigo 💪🏻"
            },
            fail: {
              us: "Oops, an error occurred. Try again",
              es: "Oops, Ocurrió un error. Intenta nuevamente"
            }
          }
        },
        footer: {
          title: {
            us: "Have an idea?",
            es: "¿Tienes una idea?"
          },
          link: {
            us: "Let's talk",
            es: "Hablemos"
          }
        }
      },
      jobs: {
        name: {
          es: "Vacantes",
          us: "Jobs"
        },
        main: {
          es: "Vacantes de empleo 📂",
          us: "Job Vacancies 📂"
        },
        secondary: {
          es: "Conoce las vacantes que tenemos disponibles",
          us: "Learn about the vacancies we have available"
        },
        remote: {
          es: "Remoto",
          us: "Remote"
        },
        local: {
          es: "Presencial",
          us: "Office"
        },
        start_inmmediately: {
          es: "Contratación inmediata",
          us: "Start inmmediately"
        },
        responsabilities: {
          es: "Responsabilidades",
          us: "Responsabilities"
        },
        skills: {
          es: "Skills",
          us: "Skills"
        },
        salary: {
          es: "Salario",
          us: "Salary"
        },
        additional_info: {
          es: "Información adicional",
          us: "Additional info"
        },
        apply: {
          es: "Aplicar",
          us: "Apply"
        },
        messages: {
          success: {
            us:
              "Thank you for applying to the vacancy. We will contact you as soon as possible 💪🏻",
            es:
              "Gracias por aplicar a la vacante. Nos comunicaremos lo antes posible contigo 💪🏻"
          },
          fail: {
            us: "Oops, an error occurred. Try again",
            es: "Oops, Ocurrió un error. Intenta nuevamente"
          }
        }
      },
      privacy_policy: {
        us: "Privacy Policy",
        es: "Política de privacidad"
      },
      form: {
        names: {
          us: "Your name",
          es: "Tu nombre"
        },
        email: {
          us: "Your Email",
          es: "Tu Email"
        },
        phone: {
          us: "Your phone",
          es: "Tu teléfono"
        },
        project_resume: {
          us: "Tell us what your project is about",
          es: "Dinos de que se trata tu proyecto"
        },
        company_name: {
          us: "Your company name",
          es: "El nombre de tu empresa"
        },
        code: {
          us: "Write the code",
          es: "Escribe el código"
        }
      },
      rules: {
        required: {
          us: "Complete this info 👀",
          es: "Te falta este campo 👀"
        },
        names: {
          us: "Tell us your name 🥺",
          es: "Dinos tu nombre 🥺"
        },
        email: {
          required: {
            us: "Complete this info 👀",
            es: "Te falta este campo 👀"
          },
          format: {
            us: "The email is a bit weird 🧐",
            es: "Está un poco estraño tu email 🧐"
          }
        }
      },
      services: {
        main_title: {
          us: "We are good at",
          es: "Somos buenos"
        },
        description: {
          us: "In what we do",
          es: "En lo que hacemos"
        }
      },
      service_details: {
        work: {
          us: "Our work",
          es: "Nuestro trabajo"
        }
      },
      brief_web: {
        main_title: {
          es: "Brief Web 💪🏻",
          us: "Web Brief 💪🏻"
        },
        main_description: {
          es: "Completa la siguiente info",
          us: "Complete the info"
        },
        start_title: {
          es: "",
          us: ""
        },
        start_description: {
          es: "",
          us: ""
        },
        send: {
          us: "Send info",
          es: "Enviar información"
        }
      },
      brief_brand: {
        main_title: {
          es: "Brief Marca 💪🏻",
          us: "Brand Brief 💪🏻"
        },
        main_description: {
          es: "Completa la siguiente info",
          us: "Complete the info"
        },
        start_title: {
          es: "",
          us: ""
        },
        start_description: {
          es: "",
          us: ""
        },
        send: {
          us: "Send info",
          es: "Enviar información"
        }
      },
      landings: {
        design: {

          sections: {
            general: {
              plans: {
                es: "Ver planes",
                us: "See plans",
              }
            },
            1: {
              title: {
                es: "Diseño de calidad para empresas con calidad",
                us: "Quality design for quality companies",
              },
              subtitle: {
                es: "Haz crecer tu empresa con diseños de alto impacto",
                us: "Grow your company with high-impact designs",
              }
            },
            2: {
              title: {
                es: "Diseña lo que necesites, cuando lo necesites",
                us: "Design what you need, when you need it",
              },
              subtitle: {
                es: "Suscripciones para escalar tu negocio.",
                us: "Suscriptions to scale your business",
              },
              items: {
                1: {
                  es: "Suscríbete a un plan y solicita tantos diseños como quieras.",
                  us: "Subscribe to a plan and request as many designs as you want."
                },
                2: {
                  es: "Recibe tus diseños en pocos días hábiles.",
                  us: "Receive your designs in a few business days."
                },
                3: {
                  es: "Revisiones hasta lograr el 100% de satisfacción.",
                  us: "Reviews until 100% satisfaction is achieved."
                },
              }
            },
            3: {
              title: {
                es: "¿Cómo funciona?",
                us: "How it works?",
              },
              subtitle: {
                es: "¡Es muy sencillo trabajar con nosotros!.",
                us: "It is very easy to work with us.",
              },
              items: {
                1: {
                  title: {
                    es: "1. Realiza una solicitud",
                    us: "1. Make a request"
                  },
                  description: {
                    es: "Completa el formulario con toda la información necesaria. Trata de ser lo más específico posible, entre más precisa la información, mejor será el resultado. <br><br>Si tenemos alguna duda, nos conectamos en un meeting de 10 minutos para solucionarlas. Te enviamos un mensaje con la estimación en tiempos de entrega, una vez apruebes, comenzamos a trabajar.",
                    us: "Subscribe to a plan and request as many designs as you want."
                  }
                },
                2: {
                  title: {
                    es: "2. Recibe tu diseño",
                    us: "2. Get your design"
                  },
                  description: {
                    es: "Una vez hayamos terminado, recibirás tu diseño en los canales de comunicación que hayamos definido para que puedas revisar. <br><br>Si tienes algún ajuste, es el momento de hacerlo.",
                    us: "Once we have finished, you will receive your design in the communication channels that we have defined so that you can review it. <br><br>If you have any adjustments, now is the time to do it."
                  }
                },
                3: {
                  title: {
                    es: "3. Disfruta",
                    us: "3. Enjoy it"
                  },
                  description: {
                    es: "Ya puedes utilizar tu diseño en producción. Te enviamos el resultado final y cerramos el ticket para dar por concluida tu solicitud. <br><br>Recuerda que puedes realizar las solicitudes que quieras.",
                    us: "You can now use your design in production. We send you the final result and close the ticket to conclude your request. <br><br>Remember that you can make the requests you want."
                  }
                },
              }
            },
            4: {
              title: {
                es: "¿Qué beneficios obtienes?",
                us: "What benefits do you get?"
              },
              subtitle: {
                es: "Es la mejor decisión que puedes tomar.",
                us: "It is the best decision you can make",
              },
              items: [
                {
                  title: {
                    es: "Ahorra dinero",
                    us: "Save money"
                  },
                  text: {
                    es: "Ahorra hasta un 50% en tu presupuesto anual destinado a equipos de diseño.",
                    us: "Save up to 50% on your annual budget for design teams."
                  },
                  col: 7
                },
                {
                  title: {
                    es: "Experiencia",
                    us: "Experience"
                  },
                  text: {
                    es: "Más de 5 años haciendo lo que amamos.",
                    us: "More than 5 years doing what we love."
                  },
                  col: 5
                },
                {
                  title: {
                    es: "Calidad",
                    us: "Quality"
                  },
                  text: {
                    es: "La mejor calidad en cada uno de los diseños que recibas.",
                    us: "The best quality in each of the designs you receive."
                  },
                  col: 5
                },
                {
                  title: {
                    es: "Ahorra tiempo",
                    us: "Save time"
                  },
                  text: {
                    es: "Tienes a tu disposición un equipo organizado que acelera el tiempo de ejecución de tus proyectos.",
                    us: "You have at your disposal an organized team that speeds up the execution time of your projects."
                  },
                  col: 7
                },
                {
                  title: {
                    es: "Enfoque",
                    us: "Approach"
                  },
                  text: {
                    es: "No te distraigas de lo que realmente eres bueno y deja el diseño en nuestras manos.",
                    us: "Don't get distracted from what you're really good at and leave the design to us."
                  },
                  col: 7
                },
                {
                  title: {
                    es: "Talento especializado",
                    us: "Specialized talent"
                  },
                  text: {
                    es: "Sincroniza un equipo con talento especializado en un solo lugar sin contratar de más.",
                    us: "Synchronize a team with specialized talent in one place without hiring more."
                  },
                  col: 5
                }
              ]
            },
            5: {
              title: {
                es: "Nunca volverá a ser como antes",
                us: "It will never be like before"
              },
              subtitle: {
                es: "Ahorra costos, optimiza tiempos y obtén en poco tiempo diseños de alta calidad.",
                us: "Save costs, optimize times and get in little time high quality designs.",
              },
              content: {
                title: {
                  es: "¡Ideal para empresas!",
                  us: "Ideal for companies!"
                },
                items: [
                  {
                    es: "Con alto flujo de solicitudes.",
                    us: "With high flow of requests."
                  },
                  {
                    es: "Con requerimientos rápidos.",
                    us: "With fast requirements."
                  },
                  {
                    es: "Comunicación masiva.",
                    us: "Massive communication."
                  },
                ],
                types: [{
                  es: "Bancos",
                  us: "Banks"
                }, {
                  es: "Gubernamental",
                  us: "Governmental"
                }, {
                  es: "Universidades",
                  us: "Universities"
                },
                {
                  es: "Tiendas de ropa",
                  us: "Clothes shop"
                },],
                stats: [
                  {
                    value: "+500",
                    text: {
                      es: "proyectos",
                      us: "projects"
                    }
                  },
                  {
                    value: "+50",
                    text: {
                      es: "clientes",
                      us: "clients"
                    }
                  },
                  {
                    value: "+10.000",
                    text: {
                      es: "diseños",
                      us: "designs"
                    }
                  },
                ]
              }
            },
            6: {
              title: {
                es: "¿Qué obtendrás con nosotros?",
                us: "What will you get with us?",
              },
              subtitle: {
                es: "Creamos propuestas que desafían los códigos del sector para diferenciar a tu empresa.",
                us: "We create proposals that challenge the codes of sector to differentiate your company.",
              },
              items: [
                {
                  title: {
                    es: "Solicitudes",
                    us: "Requests"
                  },
                  description: {
                    es: "Agrega tantas solicitudes de diseño como necesites.",
                    us: "Add as many design requests as you need."
                  },
                  icon: "items-solicitudes.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Entregas rápidas",
                    us: "Fast deliveries"
                  },
                  description: {
                    es: "Obtén tus diseños en tiempos excepcionales.",
                    us: "Get your designs in exceptional times."
                  },
                  icon: "items-entregas-rapidas.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Satisfacción",
                    us: "Satisfaction"
                  },
                  description: {
                    es: "Revisaremos tus diseños hasta que estes 100% satisfecho.",
                    us: "We will review your designs until you are 100% satisfied."
                  },
                  icon: "items-satisfaccion.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Calidad garantizada",
                    us: "Guaranteed quality"
                  },
                  description: {
                    es: "La máxima calidad en todos los diseños que te entreguemos.",
                    us: "The highest quality in all the designs that we deliver to you."
                  },
                  icon: "items-calidad-garantizada.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "No pierdes",
                    us: "You don't lose"
                  },
                  description: {
                    es: "Puedes pausar sin perder lo que has invertido.",
                    us: "You can pause without losing what you have invested."
                  },
                  icon: "items-no-pierdes.json",
                  size: 160,
                  margin_top: -20,
                  margin_bottom: -20
                },
                {
                  title: {
                    es: "Tarifa fija mensual",
                    us: "Fixed monthly rate"
                  },
                  description: {
                    es: "Sin precios ocultos, paga un precio fijo cada mes.",
                    us: "No hidden prices, you pay a fixed price every month."
                  },
                  icon: "items-tarifa-fija-mensual.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Profesionales",
                    us: "Professionals"
                  },
                  description: {
                    es: "Un equipo de profesionales disponible para ti.",
                    us: "A team of professionals available for you."
                  },
                  icon: "items-profesionales.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Ahorras dinero",
                    us: "You save money"
                  },
                  description: {
                    es: "Sin gastos fijos de personal.",
                    us: "No fixed personnel costs."
                  },
                  icon: "items-ahorras-dinero.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
                {
                  title: {
                    es: "Optimizas recursos",
                    us: "You optimize resources"
                  },
                  description: {
                    es: "Nuestro equipo soluciona todo por ti.",
                    us: "Our team solves everything for you."
                  },
                  icon: "items-optimizas-recursos.json",
                  size: 120,
                  margin_top: 0,
                  margin_bottom: 0
                },
              ]
            },
            7: {
              title: {
                es: "¿Quieres ver lo que hacemos?",
                us: "Do you want to see what we do?",
              },
              subtitle: {
                es: "No es nada común ¡es lo mejor!",
                us: "It's not common, it's the best!",
              },
              projects: [
                /*{
                  title: "BROSTORE",
                  description: {
                    es: "Inspirados en el estilo Varsity, construímos una marca que evoca la hermandad de los fundadores detrás de la marca y a su vez brinda un aspecto sólido y moderno.",
                    us: "Inspired by the Varsity style, we built a brand that evokes the brotherhood of the founders behind the brand and in turn provides a solid and modern look."
                  },
                  service: "Branding & Packaging.",
                  image: "brostore.png"
                },
                {
                  title: "FIBBRA",
                  description: {
                    es: "Fibbra es un estudio de arquitectura y diseño de interiores con el ideal de crear espacios únicos y armoniosos que brinden frescura y comodidad.<br><br>Trabajamos en darle mucha fuerza e identidad a la marca, comenzando con el naming, donde exaltamos su esencia haciéndole homenaje a su razón de ser.",
                    us: "Fibbra is an architecture and design studio for interiors with the ideal of creating unique spaces and harmonious that provide freshness and comfort.<br><br>We work to give a lot of strength and identity to the brand, starting with the naming, where we exalt its essence paying homage to its Reason to be."
                  },
                  service: "Naming, Rebranding & Packaging.",
                  image: "fibbra.png"
                },
                {
                  title: "OPEN",
                  description: {
                    es: "En la construcción de la marca buscamos representar las características de la ingeniería a través del estilo del brutalismo, como la  construcción en bloques y el juego de espacios para permitir la entrada de la luz.",
                    us: "In the construction of the brand we seek to represent the characteristics of engineering through the style of brutalism, such as the construction in blocks and the game of spaces to allow the entry of light."
                  },
                  service: "Rebranding.",
                  image: "open.png"
                },
                {
                  title: "CLO",
                  description: {
                    es: "Conceptualizamos la marca resaltando una personalidad arriesgada, con mucha actitud y personalidad. Resaltamos estos atributos a través de la paleta de colores, su estilo y tono de comunicación.",
                    us: "We conceptualized the brand highlighting a risky personality, with a lot of attitude and personality. We highlight these attributes through the color palette, its style and tone of communication."
                  },
                  service: "Brand identity",
                  image: "clo.png"
                },
                {
                  title: "SEP",
                  description: {
                    es: "Construímos una identidad que reflejara un estilo moderno combinado con formas geométricas que generan una armonía en su identidad, expresando su versatilidad y funcionalidad al igual que su espíritu aventurero y arriesgado.",
                    us: "We built an identity that reflected a modern style combined with geometric shapes that generate harmony in its identity, expressing its versatility and functionality as well as its adventurous and risky spirit."
                  },
                  service: "Rebranding",
                  image: "sep.png"
                },
                {
                  title: "PRIVATA",
                  description: {
                    es: "Privata es una marca de abogados, en la que se buscó dar un aspecto moderno y sofisticado a través de la construcción de su logotipo y su estilo de comunicación, siendo una marca cercana, joven y confiable.",
                    us: "Privata is a brand of lawyers, which sought to give a modern and sophisticated look through the construction of its logo and its communication style, being a close, young and reliable brand."
                  },
                  service: "Brand identity",
                  image: "privata.png"
                }*/
              ]
            },
            8: {
              title: {
                es: "¿En qué áreas te podemos apoyar?",
                us: "In what areas can we support you?",
              },
              subtitle: {
                es: "Impulsamos tu negocio para ser más competitivos mediante diseños de alta calidad en las siguientes áreas:",
                us: "We boost your business to be more competitive through high-quality designs in the following areas:",
              },
              subtitle_2: {
                es: "Y mucho más",
                us: "And much more",
              },

              areas: [{
                es: "Social media graphics",
                us: "Social media graphics"
              }, {
                es: "Infografías",
                us: "infographics"
              }, {
                es: "Ilustraciones",
                us: "Illustrations"
              }, {
                es: "Presentaciones",
                us: "Presentations"
              }, {
                es: "Firmas de correo",
                us: "Mail signatures"
              }, {
                es: "Brochures",
                us: "Brochures"
              }, {
                es: "Membretes",
                us: "Letterheads"
              }, {
                es: "Tarjetas de presentación",
                us: "Business cards"
              }, {
                es: "Mockups",
                us: "Mockups"
              }, {
                es: "Landing pages",
                us: "Landing pages"
              }, {
                es: "Packaging",
                us: "Packaging"
              }, {
                es: "Vallas publicitarias",
                us: "Billboards"
              }, {
                es: "Iconografía",
                us: "Iconography"
              }, {
                es: "Diseño de bolsas ",
                us: "Bag design"
              }, {
                es: "Carpetas",
                us: "Folders"
              }, {
                es: "Escarapelas",
                us: "Cockades"
              }, {
                es: "Pendones",
                us: "Banners"
              }, {
                es: "Backing",
                us: "Backing"
              }, {
                es: "Hoodies",
                us: "Hoodies"
              }, {
                es: "Camisas",
                us: "Shirts"
              },]
            },
            9: {
              title: {
                es: "Planes que manejamos",
                us: "Plans we handle",
              },
              subtitle: {
                es: "Selecciona el plan que mejor se acomode a tus necesidades.",
                us: "Select the plan that best suits your needs.",
              },
              popular_banner: {
                es: "Más popular",
                us: "Most popular"
              },
              plans: [
                /*{
                  name: "Standard",
                  description: {
                    es: "Contenidos limitados. Sin compromiso mínimo. Pausa o cancela en cualquier momento.",
                    us: "Limited contents. No minimum commitment. Pause or cancel at any time."
                  },
                  price_month: "$500/m",
                  price_year: "$400/m",
                  save: {
                    es: "Ahorra <b>1,200 USD</b>",
                    us: "Save <b>1,200 USD</b>",
                  },
                  price_info: {
                    es: "Pausa o cancela en cualquier momento",
                    us: "Pause or cancel anytime"
                  },
                  actions: {
                    button: {
                      es: "Empezar ahora",
                      us: "Start now",
                      link: "https://wa.me/12016925201?text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20del%20plan%20Standard"
                    },
                    call: {
                      es: "Agendar una llamada",
                      us: "Schedule a call",
                      link: "https://calendly.com/digital-exp-design/30min"
                    }
                  },
                  details: {
                    title: {
                      es: "¿Que incluye?",
                      us: "What includes?"
                    },
                    items: [
                      {
                        es: "Diseños ilimitados al mes para redes sociales.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Diseños ilimitados al mes de presentaciones.",
                        us: "Unlimited designs per month for presentations."
                      },
                      {
                        es: "Diseños ilimitados al mes para artes impresos.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Usuarios ilimitados (próximamente).",
                        us: "Unlimited users (coming soon)."
                      }, {
                        es: "Solicitud de diseños optimizada.",
                        us: "Request for designs optimized."
                      }, {
                        es: "Licencia de stock de fotos.",
                        us: "Stock photo license."
                      }, {
                        es: "Prioridad en tiempos de respuesta.",
                        us: "Priority in response time."
                      },
                      {
                        es: "1 proyecto o solicitud a la vez.",
                        us: "1 project or request at a time."
                      },
                      {
                        es: "Pausa o cancela en cualquier momento.",
                        us: "Pause or cancel at any time."
                      },
                    ]
                  }
                },
                {
                  name: "Business",
                  description: {
                    es: "Contenidos limitados. Sin compromiso mínimo. Pausa o cancela en cualquier momento.",
                    us: "Limited contents. No minimum commitment. Pause or cancel at any time."
                  },
                  price_month: "$1,000/m",
                  price_year: "$900/m",
                  save: {
                    es: "Ahorra <b>1,200 USD</b>",
                    us: "Save <b>1,200 USD</b>",
                  },
                  price_info: {
                    es: "Pausa o cancela en cualquier momento",
                    us: "Pause or cancel anytime"
                  },
                  actions: {
                    button: {
                      es: "Empezar ahora",
                      us: "Start now",
                      link: "https://wa.me/12016925201?text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20del%20plan%20Business"
                    },
                    call: {
                      es: "Agendar una llamada",
                      us: "Schedule a call",
                      link: "https://calendly.com/digital-exp-design/30min"
                    }
                  },
                  details: {
                    title: {
                      es: "¿Que incluye?",
                      us: "What includes?"
                    },
                    items: [
                      {
                        es: "Diseños ilimitados al mes de landing pages.",
                        us: "Unlimited designs per month for landing pages."
                      },
                      {
                        es: "Diseños ilimitados al mes para redes sociales.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Diseños ilimitados al mes de presentaciones.",
                        us: "Unlimited designs per month for presentations."
                      },
                      {
                        es: "Diseños ilimitados al mes para artes impresos.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Usuarios ilimitados (próximamente).",
                        us: "Unlimited users (coming soon)."
                      }, {
                        es: "Solicitud de diseños optimizada.",
                        us: "Request for designs optimized."
                      }, {
                        es: "Licencia de stock de fotos.",
                        us: "Stock photo license."
                      }, {
                        es: "Prioridad en tiempos de respuesta.",
                        us: "Priority in response time."
                      },
                      {
                        es: "2 proyectos o solicitudes a la vez.",
                        us: "2 projects or requests at a time."
                      },
                      {
                        es: "Pausa o cancela en cualquier momento.",
                        us: "Pause or cancel at any time."
                      },
                    ]
                  }
                },
                {
                  name: "Pro",
                  description: {
                    es: "Contenidos ilimitados. Ahorra $500 por mes.",
                    us: "Ilimited contents. Save $500 per month."
                  },
                  price_month: "$1,500/m",
                  price_year: "$1,350/m",
                  save: {
                    es: "Ahorra <b>1,800 USD</b>",
                    us: "Save <b>1,800 USD</b>",
                  },
                  price_info: {
                    es: "Pago cada 4 meses",
                    us: "Payment every 4 months"
                  },
                  actions: {
                    button: {
                      es: "Empezar ahora",
                      us: "Start now",
                      link: "https://wa.me/12016925201?text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20del%20plan%20Pro"
                    },
                    call: {
                      es: "Agendar una llamada",
                      us: "Schedule a call",
                      link: "https://calendly.com/digital-exp-design/30min"
                    }
                  },
                  details: {
                    title: {
                      es: "¿Que incluye?",
                      us: "What includes?"
                    },
                    items: [
                      {
                        es: "Diseños ilimitados al mes de landing pages.",
                        us: "Unlimited designs per month for landing pages."
                      },
                      {
                        es: "Diseños ilimitados al mes para redes sociales.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Diseños ilimitados al mes de presentaciones.",
                        us: "Unlimited designs per month for presentations."
                      },
                      {
                        es: "Diseños ilimitados al mes para artes impresos.",
                        us: "Unlimited designs per month for social networks."
                      },
                      {
                        es: "Edición de video.",
                        us: "Video edition."
                      },
                      {
                        es: "Usuarios ilimitados (próximamente).",
                        us: "Unlimited users (coming soon)."
                      }, {
                        es: "Solicitud de diseños optimizada.",
                        us: "Request for designs optimized."
                      }, {
                        es: "Licencia de stock de fotos.",
                        us: "Stock photo license."
                      }, {
                        es: "Prioridad en tiempos de respuesta.",
                        us: "Priority in response time."
                      },
                      {
                        es: "2 proyectos o solicitudes a la vez.",
                        us: "2 projects or requests at a time."
                      },
                      {
                        es: "Cambios ilimitados.",
                        us: "Unlimited changes."
                      },
                      {
                        es: "Pausa o cancela en cualquier momento.",
                        us: "Pause or cancel at any time."
                      },
                    ]
                  }
                },*/
              ]
            },
            10: {
              title: {
                es: "Preguntas frecuentes",
                us: "Frequent questions",
              },
              subtitle: {
                es: "¿Tienes dudas? Revisa esta serie de preguntas y respuestas.",
                us: "You have doubts? Review this series of questions and answers.",
              },
              questions: [
                /*{
                  question: {
                    es: "¿Hay un límite en la cantidad de solicitudes que puedo tener?",
                    us: "Is there a limit on the number of requests I can have?"
                  },
                  answer: {
                    es: "Una vez suscrito, puede agregar tantas solicitudes de diseño a su cola como desee y se entregarán una por una.",
                    us: "Once subscribed, you can add as many design requests to your paste as you wish and they will be delivered one by one."
                  }
                },
                {
                  question: {
                    es: "¿Qué tan rápido recibo mis diseños?",
                    us: "How fast do I receive my designs?"
                  },
                  answer: {
                    es: "",
                    us: ""
                  }
                },
                {
                  question: {
                    es: "¿Quiénes son los encargados de diseñar?",
                    us: "Who are those in charge of designing?"
                  },
                  answer: {
                    es: "",
                    us: ""
                  }
                },
                {
                  question: {
                    es: "¿En cuáles programas diseñan?",
                    us: "What programs are you designing?"
                  },
                  answer: {
                    es: "",
                    us: ""
                  }
                },
                {
                  question: {
                    es: "¿Qué pasa si no me gusta el diseño?",
                    us: "What happens if I don't like the design?"
                  },
                  answer: {
                    es: "",
                    us: ""
                  }
                },
                {
                  question: {
                    es: "¿Hay algún reembolso?",
                    us: "Are there any refunds?"
                  },
                  answer: {
                    es: "",
                    us: ""
                  }
                }*/
              ]
            },
            11: {
              title: {
                es: "Somos los más indicados para ti",
                us: "We are the most suitable for you",
              },
              subtitle: {
                es: "Agenda una llamada y descubre cómo tú y tu equipo pueden cambiar la forma en que obtienen el diseño, para siempre.",
                us: "Schedule a call and find out how you and your team can change the way you get design, forever.",
              },
              action: {
                es: "Empezar ahora",
                us: "Start now"
              },
              action2: {
                es: "¡Quiero más información!",
                us: "I want more info!"
              }
            },
            12: {
              items: [
                {
                  name: {
                    es: "Política de privacidad",
                    us: "Privacy policy"
                  },
                  url: ""
                },
                {
                  name: {
                    es: "Términos y condiciones",
                    us: "Terms & conditions"
                  },
                  url: ""
                }
              ]
            }
          }
        }
      },
      page404: {
        title: {
          es: "No encontramos la página que buscabas 🥺",
          us: "We couldn't find the page you were looking for 🥺"
        },
        content: {
          es: "Intenta navegando por nuestro sitio para encontrar lo que buscas",
          us: "Try browsing our site to find what you are looking for."
        },
        action: {
          es: "Ir al inicio",
          us: "Go home"
        }
      }
    },
    services: {} as any,
    servicesArray: [] as any,
    jobs: {} as any,
    jobsArray: [] as any,
    vacancies: [] as any,
    selectedMedia: new File([""], "") as File
  },
  mutations: {},
  actions: {},
  modules: {}
});
