
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class ButtonMenu extends mixins(mixin) {
  @Prop() name;
  @Prop() routeName;

  mounted() {
    console.log("Dark");
  }

  goRoute() {
    this.$store.state.showFooter = true;
    const menu = document.getElementById("menuToggle")! as HTMLInputElement;
    menu.checked = false;
    console.log(menu.checked);
    this.$router.replace({
      name: this.routeName,
      //target: "_blank"
    });
  }
}
