
import mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class ButtonOutlined extends mixins(mixin) {
  @Prop() currentID;
  @Prop() name;
  @Prop() type;
  @Prop() slug;

  /*mounted() {
    console.log("ButtonOutlined mounted");
    console.log(this.currentID);
  }*/

  goToProjectDetail() {
    if (this.type === "project") {
      this.$router.push({
        name: "project-details",
        params: {
          slug: this.slug
        }
        //target: "_blank"
      });
    } else {
      this.$router.push({
        name: "service-details",
        params: {
          slug: this.slug
        }
        //target: "_blank"
      });
      console.log(this.slug);
    }
  }

  getSlug() {
    console.log("getSlug");
  }
}
